import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Post from './Post'
import GetFunction from '../API/getFunction';
import PostFunction from "../API/postFunction";
import DeleteFunction from "../API/deleteFunction";

//DECLARATION MUI
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Skeleton from '@mui/material/Skeleton';
import Fab from '@mui/material/Fab';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import BackButton from '../Navigation/BackButton';

// DECLARATIONS REACT ROUTER
import { useParams, useNavigate } from 'react-router-dom'

// DECLARATIONS STORE

export default function Feed() {

  const selectToken = state => state.token
  const token = useSelector(selectToken)
        
  const selectMarkersArray = state => state.markersArray
  const markersArray = useSelector(selectMarkersArray)

  const selectSubscriptionsArray = state => state.subscriptionsArray
  const subscriptionsArray = useSelector(selectSubscriptionsArray)

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [feedState, setFeedState] = useState({
    feedListData : null,
    feedList : null,
    feedSubscription: 0,
    isFetchingFeed: true,
    isFetchingSubscriptions: true,
  })

  // On récupère le spotId en paramètre du lien
  let {spotId} = useParams()

  const [spot, setSpot] = useState()

  // State permettant d'afficher l'icône correspondant à l'état de subscription (vrai ou faux)
  const [spotSubscribed, setSpotSubscribed] = useState(null)

  // State permettant de gérer les subscription en cours de fetch
  const [isSubscribing, setIsSubscribing] = useState(null)
  
  // pull-to-refresh : states pour conserver le point de départ + conserver la distance de pull
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Fonction pour forcer le refresh
  const RefreshFeed = () => {
    // Récupération du nouveau feed
    setIsRefreshing(true)
    GetFunction({fetchTarget : 'spotFeedContent', fetchArgument:spotId, token:token})
    .then(response => {
      setStartPoint(0);
      setPullChange(0);
      setIsRefreshing(false)
      if(response.fetchStatus === 'Ok') {
        console.log('Feed.js -> Mise à jour du statut feedListData')
        setFeedState(prevState => ({...prevState, feedListData : response.data})) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
      } else {
        console.log('Feed.js -> Impossible de charger le contenu du feed')
      }
    })
  };

  // Fonction pour gérer le pullStart
  const pullStart = (e) => {
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
    console.log('PULLSTART')
    console.log(screenY)
  };

  // Fonction pour gérer le pull
  const pull = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = (startPoint < screenY ? Math.abs(screenY - startPoint) : 0);
    setPullChange(pullLength);
    console.log('PULL')
    console.log(screenY)
    console.log('->pullLength  :')
    console.log(pullLength)
  };

  // Fonction pour gérer le endPull
  const endPull = (e) => {
    if (pullChange > 220) {
      RefreshFeed()
    } else {
      setStartPoint(0);
      setPullChange(0);
    }
    console.log('ENDPULL')
    console.log('->pullChange  :')
    console.log(pullChange)
  };

  // Mise en place et suppression des event listeners pour gérer le pull-to-refresh
  useEffect(() => {
    window.addEventListener("touchstart", pullStart); // L'écran est touché
    window.addEventListener("touchmove", pull);       // Mouvement du doigt
    window.addEventListener("touchend", endPull);     // L'écran est lâché
    return () => {
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  });
  
  // Fonction qui contruit le contenu du fil (améliore les performances : évite de recalculer
  // le contenu du fil à chaque render, car il y a bcp de render avec le PullToRefresh)
  function BuildFeedList(props) {
    if (props.feedContent.length > 0) {
      return(
        props.feedContent.map((item) => 
          <Post
            key={item.pk.toString()}
            postScreen="SpotFeed"
            post={item}
            spot={spot}
            refreshFunction={RefreshFeed}
          />
        )
      )
    } else {
      return(
        <Typography align="center" variant="body1" color="primary">
          Il n'y a pas encore de contribution pour ce lieu.
        </Typography>
      )
    }
  }

  // Fonction de chargement du contenu du feed, lancée dans le useEffect
  function LoadFeedContent() {
    console.log('Feed.js -> Chargement API spotFeedContent')
    setFeedState(prevState => ({...prevState, isFetchingFeed : true})) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
    GetFunction({fetchTarget : 'spotFeedContent', fetchArgument:spotId, token:token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('Feed.js -> Fin chargement API spotFeedContent')
        console.log('Feed.js -> Mise à jour du statut feedListData')
        setFeedState(prevState => ({...prevState, feedListData : response.data, isFetchingFeed : false})) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
      } else {
        console.log('Feed.js -> Impossible de charger le contenu du feed')
        setFeedState(isFetchingFeed => false) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre

        let errorMessage = "Impossible de trouver les conversation de ce lieu contenu, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })

    console.log('Feed.js -> Chargement API spotSubscriptionsCount')
    GetFunction({fetchTarget : 'spotSubscriptionsCount', fetchArgument : spotId, token : token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('Feed.js -> Fin chargement API spotSubscriptionsCount')
        console.log('Feed.js -> Mise à jour du statut feedSubscription')
        setFeedState(prevState => ({...prevState, feedSubscription : response.data[0].nbSubscriptions, isFetchingSubscriptions : false}))
      } else {
        console.log("Feed.js -> Impossible de charger le nombre d'abonnés du feed")
        setFeedState(prevState => ({...prevState, isFetchingSubscriptions :false}))
        let errorMessage = "Impossible de trouver le nombre d'abonnés à ce lieu contenu, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function ViewFeedMembers() {
    if (feedState.feedSubscription>0)
    {
      let nextPage = "/FeedMembers/"
      navigate(nextPage, {state : {spot : spot}})
    }
  }

  useEffect(() => {
    console.log('Feed.js -> useEffect : chargement du contenu du feed')
    LoadFeedContent()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[spotId]  // Syntaxe pour que le hook soit exécuté quand on change de spotId : pour permettre de naviguer de la page d'un spot à un autre avec les chips
  );   

  useEffect(() => {
    console.log('Feed.js -> useEffect : génération du contenu formaté du feed')
    // On ne crée les objets <Post> que si on a terminé de récupérer les données du spot
    if (spot) {
      if (feedState.feedListData) {
        setFeedState(prevState => ({...prevState, feedList : BuildFeedList({feedContent : feedState.feedListData})})) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
      }
    }
    },[spot, feedState.feedListData]  // Syntaxe pour que le hook soit exécuté quand on change de spotId : pour permettre de naviguer de la page d'un spot à un autre avec les chips
  );   

  // Identification des données du spot
  useEffect(() => {
    console.log('SpotFeed.js -> useEffect : identification des données du spot')
    if (markersArray.length > 0) {
      let newSpot = markersArray.find(marker => (marker.pk.toString() === spotId.toString()))
      let markerImgZoom
      if (newSpot.spotType === "Port") {
        markerImgZoom = 15
      } else if (newSpot.spotType === "Mouillage") {
        markerImgZoom = 12
      }
      setSpot({...newSpot, markerImgZoom : markerImgZoom})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[spotId, markersArray]  // Syntaxe pour que le hook soit exécuté quand on change de spotId : pour permettre de naviguer de la page d'un spot à un autre avec les chips
  );   

  // Identification de la souscription du spot
  useEffect(() => {
    console.log('SpotFeed.js -> useEffect : identification de la souscription du spot')
    if (spot) {
      if (subscriptionsArray.length > 0) {
        if (subscriptionsArray.findIndex(id => (spot.pk === id.subscriptionSpot)) !== -1) {
          setSpotSubscribed("subscribed")
        } else {
          setSpotSubscribed("unsubscribed")
        }
      } else {
        setSpotSubscribed("unsubscribed")
      }
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[spot, subscriptionsArray]  // Syntaxe pour que le hook soit exécuté quand on change de spotId : pour permettre de naviguer de la page d'un spot à un autre avec les chips
  );   

  function Subscribe() {
    let fetchArgument = {
      'subscriptionUser' : myProfile.pk, //Utilisé pour que le champs soit présent et avec un bon format, mais cet id sera écrasé par le userid dans l'API
      'subscriptionSpot' : spot.pk
    }
    setIsSubscribing('subscribing')
    // Lancement animation des points gagnés
    console.log('SpotFeed -> Déclenchement animation points gagnés subscribe')
    dispatch({ type : "TOGGLE_SCOREDPOINTS_ANIMATION", payload : 50})

    PostFunction({fetchTarget : 'postSubscription', fetchArgument : fetchArgument, token : token})
    .then(response => {
      setIsSubscribing(null)
      if(response.fetchStatus === 'Ok') {
        console.log('SpotFeed.js -> Fin chargement API toggleSubscription')
        console.log('SpotFeed.js -> Mise à jour icone')
        setSpotSubscribed("subscribed")
        console.log('SpotFeed.js -> Fetch du nouvel état de subscriptionArray')
        GetFunction({fetchTarget : 'subscriptionsArray',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('SpotFeed.js -> Chargement nouveau subscriptionsArray dans le state Redux')
            dispatch({ type : "LOAD_SUBSCRIPTIONS_ARRAY", payload:response.data})
          } else {
            console.log('SpotFeed.js -> Impossible de charger le nouveau subscriptionsArray')
            let errorMessage = "Impossible d'afficher votre nouvel abonnement, vérifiez votre connexion"
            dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
          }
          // Récupération du nouveau newsFeed
          GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
          .then((response) => {
            if (response.fetchStatus === 'Ok') {
              console.log('SpotFeed.js -> Chargement newsFeedContent dans le state Redux')
              dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
            } else {
              console.log('SpotFeed.js -> Erreur dans le fetch newsFeedContent')
            }
          })
        })
        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('SpotFeed.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('SpotFeed.js -> Réception du profil à jour en échec')
          }
        })

      } else {
        console.log('SpotFeed.js -> Chargement API toggleSubscription en erreur')
        let errorMessage = "Impossible de prendre en compte votre abonnement, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function Unsubscribe() {
    console.log('SpotFeed.js -> unsubscribe')
    // On va chercher dans la liste des subscriptions la clé primaire de la subscription à supprimer
    let markerPk = subscriptionsArray[subscriptionsArray.findIndex(id => (spot.pk === id.subscriptionSpot))].pk 
    let fetchArgument = {
      'pk' : markerPk
      }
    setIsSubscribing('unsubscribing')
    DeleteFunction({fetchTarget : 'deleteSubscription', fetchArgument : fetchArgument, token : token})
    .then(response => {
      setIsSubscribing(null)
      if(response.fetchStatus === 'Ok') {
        console.log('SpotFeed.js -> Fin chargement API toggleSubscription')
        console.log('SpotFeed.js -> Mise à jour icone')
        setSpotSubscribed("unsubscribed")
        console.log('SpotFeed.js -> Fetch du nouvel état de subscriptionArray')
        GetFunction({fetchTarget : 'subscriptionsArray', fetchArgument : null, token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('SpotFeed.js -> Chargement nouveau subscriptionsArray dans le state Redux')
            dispatch({ type : "LOAD_SUBSCRIPTIONS_ARRAY", payload:response.data})
          } else {
            console.log('SpotFeed.js -> Impossible de charger le nouveau subscriptionsArray')
            let errorMessage = "Impossible d'afficher votre désabonnement, vérifiez votre connexion"
            dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
          }
        })
        // Récupération du nouveau newsFeed
        GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('SpotFeed.js -> Chargement newsFeedContent dans le state Redux')
            dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
          } else {
            console.log('SpotFeed.js -> Erreur dans le fetch newsFeedContent')
          }
        })
        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('SpotFeed.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('SpotFeed.js -> Réception du profil à jour en échec')
          }
        })
      } else {
        console.log('SpotFeed.js -> Chargement API toggleSubscription en erreur')
        let errorMessage = "Impossible de prendre en compte votre désabonnement, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function SubscriptionButton() {
    return (
      spotSubscribed ?
        <Fab
          color="white"
          onClick={spotSubscribed === "subscribed" ? Unsubscribe : Subscribe}
          sx={{padding : 3}}
          size = "small"
        >
          {isSubscribing ?
            (isSubscribing === "subscribing" ?
              <FavoriteIcon color="secondary"/>
              :
              <FavoriteBorderIcon color="primary"/>
            )
            :
            (spotSubscribed === "subscribed" ?
              <FavoriteIcon color="secondary"/>
              :
              <FavoriteBorderIcon color="primary"/>
            )
          }
        </Fab>
        :
        null
    )
  }

  function NewPostButton() {
    return (
      <Fab
        color="success"
        onClick={() => navigate("/FeedNewPost" , {state : {spot : spot}})}
        sx={{padding : 1, position : "fixed", right : {xs : 20, sm : 30, md : 40}, bottom : {xs : 20, sm : 30, md : 40}}}
        >
        <SendIcon sx={{color : "white", fontSize: 40 }}/>
      </Fab>
    );
  }

  function ZoomToSpotButton() {
    return (
      <Fab
        color="primary"
        onClick={() => navigate("/MapPage" , {state : {zoomToSearch : spot}})}
        sx={{padding : 1, position : "absolute", top : 80}}
        >
        <LocationOnIcon sx={{color : "white", fontSize: 40 }}/>
      </Fab>
    );
  }


  function SpotFeed() {
    if (!feedState.isFetchingFeed & !feedState.isFetchingSubscriptions & (spot !== null)) {
      return(
        <Box sx={{display : "flex", position : "relative", flexDirection : "column", backgroundColor : "white"}} width="100%" height="100%"> {/* Il faut préciser des dimensions pour pouvoir fixer ensuite une box en position absolute ou pour que les flex enfants prennent toute la place. Et il faut le Relative pour que les enfants se positionnent bien en absolute*/}
          <BackButton/>
          <Box sx={{marginTop : "-150px", display : "flex", flexDirection : "column", alignItems : "center",borderBottom : 15, borderColor : "#FCE2D4"}}>
            <Avatar
              style={{
                border: '15px solid #f3722C' // Seule synthaxe qui fonctionne pour créer un border 
              }}
              src={"https://maps.googleapis.com/maps/api/staticmap?center=" + spot.spotLatitude + "," + spot.spotLongitude + "&zoom=" + spot.markerImgZoom + "&size=400x400&maptype=satellite&key=AIzaSyATHwwDt6IBo65JOtA0bxsJ7G8r7lk4Fdk"}  
              sx={{width: 300, height: 300}}
            />
            {ZoomToSpotButton()}
            <Box sx={{
              display : "flex",
              flexDirection : "row",
              alignItems : "center",
              padding : 1
            }}>
              <Box sx={{
                display : "flex",
                flexGrow :1
              }}
              >
                <Typography variant = "h4" color={"primary"} onClick={() => navigate("/MapPage" , {state : {zoomToSearch : spot}})} >
                  {spot.spotName}
                </Typography>
              </Box>
              {SubscriptionButton()}
            </Box>
            <Button size="small"
              onClick={ViewFeedMembers}
            >
              {feedState.feedSubscription.toString() + " abonné(es)"}
            </Button>
          </Box>
          <Container sx={{display : "flex", height : Math.min(pullChange,50+Math.max(0,pullChange-50)/5)}}>
            <Box sx={{ flex : 1}}/>
            {(isRefreshing || pullChange > 100) ? <CircularProgress/>:null}
            <Box sx={{ flex : 1}}/>
          </Container>
          {feedState.feedList}
          {NewPostButton()}
        </Box>
      )
    } else {
      return (
        <Box sx={{position : "absolute", display : "flex", flexDirection : "column", backgroundColor : "white"}} height="100%" width = "100%" overflow = "auto"> {/* Il faut préciser des dimensions pour pouvoir fixer ensuite une box en position absolute ou pour que les flex enfants prennent toute la place*/}
          <BackButton/>
            <Box sx={{display:"flex", flexDirection:"row", paddingRight : {xs : 0 , sm : 2, md : 5}, alignItems:"center"}}>
              <Box sx={{flex:1}}>
                  <Skeleton variant="circular" width={70} height={70} marginleft={1} marginright={1} />
              </Box>
              <Box sx={{flex:12}}>
                <Skeleton variant="text" sx={{ fontSize: '6rem' }} />
              </Box>
              <BackButton/>
            </Box>
            <Box sx={{ display : "flex", flexDirection : "column"}}>
              <Skeleton variant="rounded" width={"90%"} height={100} sx={{padding : 3, margin : 3}}/>
              <Skeleton variant="rounded" width={"90%"} height={100} sx={{padding : 3, margin : 3}}/>
              <Skeleton variant="rounded" width={"90%"} height={100} sx={{padding : 3, margin : 3}}/>
            </Box>
          </Box>
      )
    }
  }

  return(
    SpotFeed()
  );
}