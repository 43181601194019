import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

export default function TutorialIntro1() {

  const navigate = useNavigate()

  function PreviousButton() {
    return (
      <Button
        onClick={() => navigate ("/TutorialStart", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function NextButton() {
    return (
      <Button
        onClick={() => navigate ("/TutorialIntro2", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogTitle color={"primary"}>
          Au port, discutez avec vos voisins, sans devoir arpenter les pontons !
        </DialogTitle>
        <Box sx={{display : "flex", flexDirection : "row", width : "100%", marginTop : 2}} justifyContent="flex-start">
          <Box sx={{display : "flex", flexDirection : "column", width : "80%"}} alignItems="flex-start">
            <Box bgcolor="#277DA1" sx={{ display: 'inline-flex', borderRadius : 2 }}> 
              <Typography style={{whiteSpace: 'pre-line'}} padding = {1} color = "white">
                J'ai besoin du code des sanitaires !
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{display : "flex", flexDirection : "row", width : "100%", marginTop : 2}} justifyContent="flex-end">
          <Box sx={{display : "flex", flexDirection : "column", width : "80%"}} alignItems="flex-end">
            <Box bgcolor="#f3722C" sx={{ display: 'inline-flex', borderRadius : 2 }}> 
              <Typography style={{whiteSpace: 'pre-line'}} padding = {1} color = "white">
                Qui pourrait me prêter une perceuse ?
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{display : "flex", flexDirection : "row", width : "100%", marginTop : 2}} justifyContent="flex-start">
          <Box sx={{display : "flex", flexDirection : "column", width : "80%"}} alignItems="flex-start">
            <Box bgcolor="#277DA1" sx={{ display: 'inline-flex', borderRadius : 2 }}> 
              <Typography style={{whiteSpace: 'pre-line'}} padding = {1} color = "white">
                Vous savez où je peux trouver un ship ouvert ?
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}