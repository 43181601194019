import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

export default function TutorialIntro3() {

  const navigate = useNavigate()

  function PreviousButton() {
    return (
      <Button
        onClick={() => navigate ("/TutorialIntro2", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function NextButton() {
    return (
      <Button
        onClick={() => navigate("/MapPage" , {replace : true, state : {tutorial : "Map1"}})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogTitle color={"primary"}>
        Et depuis chez vous, restez connecté avec vos spots préférés 
        </DialogTitle>
        <Box sx={{display : "flex", flexDirection : "row", width : "100%", marginTop : 2}} justifyContent="flex-start">
          <Box sx={{display : "flex", flexDirection : "column", width : "80%"}} alignItems="flex-start">
            <Box bgcolor="#277DA1" sx={{ display: 'inline-flex', borderRadius : 2 }}> 
              <Typography style={{whiteSpace: 'pre-line'}} padding = {1} color = "white">
                Salut les voisins, qui pourrait  vérifier mes amarres ?
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{display : "flex", flexDirection : "row", width : "100%", marginTop : 2}} justifyContent="flex-end">
          <Box sx={{display : "flex", flexDirection : "column", width : "80%"}} alignItems="flex-end">
            <Box bgcolor="#f3722C" sx={{ display: 'inline-flex', borderRadius : 2 }}> 
              <Typography style={{whiteSpace: 'pre-line'}} padding = {1} color = "white">
                On se fait un barbecue au port le week-end prochain ?
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{display : "flex", flexDirection : "row", width : "100%", marginTop : 2}} justifyContent="flex-start">
          <Box sx={{display : "flex", flexDirection : "column", width : "80%"}} alignItems="flex-start">
            <Box bgcolor="#277DA1" sx={{ display: 'inline-flex', borderRadius : 2 }}> 
              <Typography style={{whiteSpace: 'pre-line'}} padding = {1} color = "white">
                Je viens bricoler ce week-end, quelqu'un pour me montrer comment on change un passe-coque ?
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}