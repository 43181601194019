import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Post from './Post'
import GetFunction from '../API/getFunction';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import Fab from '@mui/material/Fab';

// Déclaration reat-router
import { useNavigate } from 'react-router-dom'


export default function NewsFeed() {
  // Connexion au store REDUX
  const newsFeedContent = useSelector(state => state.newsFeedContent)

  const selectMarkersArray = state => state.markersArray
  const markersArray = useSelector(selectMarkersArray)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectSubscriptionsArray = state => state.subscriptionsArray
  const subscriptionsArray = useSelector(selectSubscriptionsArray)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // State du contennu
  const [feedList, setFeedList] = useState()

  const [subscribedMarkersArray, setSubscribedMarkersArray] = useState([])

  // pull-to-refresh
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Hook qui contruit le contenu du fil à chaque fois que le contenu du newsFeed change
  // (améliore les performances : évite de recalculer le contenu du fil à chaque render,
  // car il y a bcp de render avec le PullToRefresh)
  useEffect(() => {
    if (newsFeedContent.length>=1) {
      setFeedList(newsFeedContent.map((item) => 
            <Post
              key={item.pk}
              postScreen="NewsFeed"
              post={item}
              refreshFunction={RefreshNewsFeed}
            />
      ))
    } else {
      if (subscribedMarkersArray.length>=1) {
        setFeedList(
          <Typography align="center" variant="body1" color="primary">
            Il n'y a pas encore de contribution dans les lieux que vous suivez.
          </Typography>
        )
      } else {
        setFeedList(
          <Typography align="center" variant="body1" color="primary">
            Vous ne suivez aucun lieu. Abonnez-vous à des spots et retrouvez ici leurs fils de discussion ! 
          </Typography>
        )
      }
    }
  }, [newsFeedContent])

  const RefreshNewsFeed = () => {
    setIsRefreshing(true)
    GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
    .then((response) => {
      setStartPoint(0);
      setPullChange(0);
      setIsRefreshing(false)
      if (response.fetchStatus === 'Ok') {
        console.log('NewsFeed.js -> Chargement newsFeedContent dans le state Redux')
        dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
      } else {
        console.log('NewsFeed.js -> Erreur dans le fetch newsFeedContent')
      }
    })
  };

  // 
  // GESTION DU PULL-TO-REFRESH
  //

  // Création des 3 fonctions des listeners

  const pullStart = (e) => {
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
    console.log('PULLSTART')
    console.log(screenY)
  };

  const pull = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = (startPoint < screenY ? Math.abs(screenY - startPoint) : 0);
    setPullChange(pullLength);
    console.log('PULL')
    console.log(screenY)
    console.log('->pullLength  :')
    console.log(pullLength)
  };

  const endPull = (e) => {
    if (pullChange > 220) {
      RefreshNewsFeed()
    } else {
      setStartPoint(0);
      setPullChange(0);
    }
    console.log('ENDPULL')
    console.log('->pullChange  :')
    console.log(pullChange)
  };

  // Déclaration des listeners
  useEffect(() => {
    window.addEventListener("touchstart", pullStart); // L'écran est touché
    window.addEventListener("touchmove", pull);       // Mouvement du doigt
    window.addEventListener("touchend", endPull);     // L'écran est lâché
    return () => {  // Déclaration qui permet de supprimer les listeners quand le component est unmounted
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  });

  // GESTION DES FAVORIS

  function SetSubscribedMarkersArray() {
    let newSubscribedMarkersArray = markersArray.filter((marker) => (subscriptionsArray.findIndex((id) => marker.pk === id.subscriptionSpot) !== -1))
    let nextSubscribedMarkersArray = newSubscribedMarkersArray.map(item => {
      let newItem
      if (item.spotType === "Port") {
        newItem = {...item, markerImgZoom : 15}
      } else if (item.spotType === "Mouillage") {
        newItem = {...item, markerImgZoom : 12}
      }
      return newItem
    });
    setSubscribedMarkersArray(nextSubscribedMarkersArray)
  }

  useEffect(() => {
    console.log('NewsFeed.js -> useEffect')
    SetSubscribedMarkersArray()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[subscriptionsArray]
  );   

  function NewPostButton() {
    return (
      <Fab
        color="success"
        onClick={() => navigate("/MapPage" , {state : {newsFeedMultiplePost : true}})}
        sx={{margin : 1, position : "fixed", right : {xs : 20, sm : 30, md : 40}, bottom : {xs : 20, sm : 30, md : 40}}}
        >
        <SendIcon sx={{color : "white", fontSize: 40 }}/>
      </Fab>
    );
  }

  function FavoritesList() {
    return (
      <Box sx={{
        display : "flex",
        flexDirection : "column",
        width : "100%",
        }}
        overflow = "auto"
      >
        <Typography  variant="h6" color="primary" fontWeight = "bold" sx={{marginLeft : 2}}>
          Mes favoris
        </Typography>

        <Box sx={{
          display : "flex",
          flexDirection : "row",
          width : "100%",
          borderBottom : 15, borderColor : "#FCE2D4"
          }}
          overflow = "auto"
        >
          {subscribedMarkersArray.map((item) =>
            <Box key = {item.pk.toString()} sx={{display : "flex", flexDirection : "column", alignItems : "center", margin : 2}}>
              <Avatar
                style={{
                  border: '3px solid #f3722C' // Seule synthaxe qui fonctionne pour créer un border 
                }}
                onClick={() => {navigate("/Feed/" + item.pk)}}
                src={"https://maps.googleapis.com/maps/api/staticmap?center=" + item.spotLatitude + "," + item.spotLongitude + "&zoom=" + item.markerImgZoom + "&size=400x400&maptype=satellite&key=AIzaSyATHwwDt6IBo65JOtA0bxsJ7G8r7lk4Fdk"}
                sx={{width: 80, height: 80}}
              />
              <Typography variant = "body1" align = "center" fontWeight = "bold" color={"primary"} onClick={() => navigate("/Feed/" + item.pk)} >
                {item.spotName}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    )
  }

  function FeedList() {
    return (
      <Box sx={{display : "flex", position : "relative", flexDirection : "column", backgroundColor : "white"}} width="100%" height="100%"> {/* Il faut préciser des dimensions pour pouvoir fixer ensuite une box en position absolute ou pour que les flex enfants prennent toute la place*/}
        <Box sx={{height : 70}}/>
        <Box sx={{ flex : 1}} overflow = "auto">
          {FavoritesList()}
          <Container sx={{display : "flex", height : Math.min(pullChange,50+Math.max(0,pullChange-50)/5)}}>
            <Box sx={{ flex : 1}}/>
            {(isRefreshing || pullChange > 100) ? <CircularProgress/>:null}
            <Box sx={{ flex : 1}}/>
          </Container>
          {feedList}
        </Box>
        {NewPostButton()}
      </Box>
    )
  }

  return(
    FeedList()
  );
}