import * as React from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';

import PostFunction from "../API/postFunction";
import GetFunction from "../API/getFunction";

import dayjs from 'dayjs'
import  'dayjs/locale/fr'
import 'dayjs/plugin/relativeTime'

// DECLARATIONS REACT ROUTER
import { useNavigate,Link as RouterLink } from 'react-router-dom' // On importe Link en l'appelant RouterLink pour ne pas avoir de confusion avec le component Link de MUI

// DECLARATIONS STORE
import { useSelector, useDispatch } from 'react-redux'

export default function Comment(props) {

  dayjs.locale('fr')

  const selectToken = state => state.token
  const token = useSelector(selectToken)
     
  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // State permettant d'afficher l'icône correspondant à l'état de like (vrai ou faux)
  // Positionné à la valeur d'origine du comment
  // On vérifie aussi si le comment est déjà liké par l'utilisateur lui-même
  const [commentLikes, setCommentLikes] = useState({
    nbLikes : props.comment.nbLikes,
    iLike : props.comment.iLike
  })

  // Pour gérer l'affichage du Popover avec le menu More
  const [moreIconAnchorElement, setMoreIconAnchorElement] = useState(null)
  const handleMoreIconClick = (event) => {
    setMoreIconAnchorElement(event.currentTarget);
  };
  const handleMoreIconClose = () => {
    setMoreIconAnchorElement(null);
  };
  const moreIconOpen = Boolean(moreIconAnchorElement);
  const moreIconId = moreIconOpen ? 'simple-popover' : undefined;
   

  function SendReport() {
    let nextPage ="/Report/" + props.postpk + "/" + props.comment.pk
    navigate(nextPage)
  }

  function DateFromNow() {
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    return(dayjs(props.comment.commentTimestamp,"YYYY-MM-DD HH:mm:ss").fromNow())
  }

  function ToggleLike() {
    if (token) {
      let fetchArgument = {
        'commentLikeAuthor' : myProfile.pk, //Utilisé pour que le champs soit présent et avec un bon format, mais cet id sera écrasé par le userid dans l'API
        'commentLikeComment' : props.comment.pk
      }
      if (props.comment.iLike) {
        // Si l'utilisateur aime  déjà : c'est un like à supprimer
        setCommentLikes(prevState => ({...prevState,
          nbLikes : prevState.nbLikes - 1,
          iLike : false
        }))
      } else {
        // Si l'utilisateur n'aime pas déjà : c'est un like à ajouter
        setCommentLikes(prevState => ({...prevState,
          nbLikes : prevState.nbLikes + 1,
          iLike : true
        }))
        // Lancement animation des points gagnés
        console.log('Comment -> Déclenchement animation points gagnés like')
        dispatch({ type : "TOGGLE_SCOREDPOINTS_ANIMATION", payload : 1})
      }
      PostFunction({fetchTarget : 'toggleCommentLike', fetchArgument : fetchArgument, token : token})
      .then(response => {
        if(response.fetchStatus === 'Ok') {
          console.log('Comment.js -> Fin chargement API toggleCommentLike')
        } else {
          console.log('Comment.js -> Chargement API toggleCommentLike en erreur')
          let errorMessage = "Impossible de prendre en compte votre like, vérifiez votre connexion"
          dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
        }
        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('Comment.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('Comment.js -> Réception du profil à jour en échec')
          }
        })
      })
      .then(() => {
        // On lance la fonction de refresh du component parent
        console.log('Comment.js -> Mise à jour du contenu')
        props.refreshFunction()
      })
    } else {
      navigate("/login")
    }
  }

  function LikeButton() {
    return(
      <Box sx={{display : "flex", flexDirection : "row", justifyContent : "flex-start"}}>
        <Button size="small"
          onClick={ToggleLike}
        >
          {(commentLikes.iLike ?
            <ThumbUpIcon  color="secondary" sx={{marginRight : 1}}/>
            :
            <ThumbUpIcon  color="primary" sx={{marginRight : 1}}/>
          )}
          {commentLikes.nbLikes}
        </Button>
      </Box>
    )
  }

  function MoreButtonMenu() {
    return(
      <Popover
        dense
        id={moreIconId}
        open={moreIconOpen}
        anchorEl={moreIconAnchorElement}
        onClose={handleMoreIconClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList dense>
          <MenuItem onClick={SendReport}>
            <ListItemIcon>
              <ReportProblemIcon/>
            </ListItemIcon>
            <ListItemText>
              Signaler
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <MoreHorizIcon/>
            </ListItemIcon>
            <ListItemText>
              Plus...
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
    )
  }

  const authorGlobalBadge = () => {
    if (props.comment.authorGlobalBadge) {
    return (
      <Avatar
      src={props.comment.authorGlobalBadge}  
      sx={{ width: 15, height: 15, marginRight : 0.5, marginLeft : 0.5 }}
    />
      )
    } else {
    return null;
    }
  };

  function AuthorLine() {
    let nextPageProfile ="/OtherUserProfile/" + props.comment.commentAuthor.toString()
    return(
      <Box
        sx={{
          display: 'flex',
          overflow : "hidden",
          whiteSpace : "nowrap",
          alignItems : "center"
        }}
      >
        <Box 
          sx={{
          display : "flex",
          flexGrow : 1,
          overflow : "hidden",
          whiteSpace : "nowrap"
        }}
        >
          <Link component={RouterLink} to={nextPageProfile} variant="subtitle2" color={(props.comment.authorIsHarbor ? "secondary" : "primary")} underline="hover">
            {props.comment.authorNickname}
          </Link>
        </Box>
        {authorGlobalBadge()}
      </Box>
    )
  }
  function DateLine() {
    return(
      <Box
        sx={{
          display: 'flex',
          overflow : "hidden",
          whiteSpace : "nowrap",
          alignItems : "center"
        }}
      >
        <Typography
          variant="caption"
          color="grey"
        >
          {DateFromNow()}
        </Typography>
      </Box>
    )
  }

  function CommentHeader() {
    return(
      <Box
        sx={{
          display : "flex",
          flexDirection : "row",
          alignItems : "center"
        }}>
        <Avatar
          src={props.comment.authorAvatarurl ? props.comment.authorAvatarurl : null}
        />
        <Box
          sx={{
            display : "flex",
            flexGrow : 1,
            flexDirection : "column",
            marginLeft : 1,
            overflow : "hidden",
            whiteSpace : "nowrap"
          }}>
          {AuthorLine()}
          {DateLine()}
          </Box>
        <IconButton
          onClick={handleMoreIconClick}
          color="primary"
          variant="rounded"
        >
          <MoreHorizIcon/>
        </IconButton>
        {MoreButtonMenu()}
      </Box>
    )
  }

  return(
    <Box
      sx={{
        position : "relative",
        display : "flex",
        flexDirection : "column",
        backgroundColor : (props.comment.authorIsHarbor ? "#fdf0e9" : "white"),
        padding : 1,
        borderBottom : 10,
        borderRight : 10,
        borderLeft : 10,
        borderColor : "#FCE2D4",
        borderRadius : 5
      }}
    >
      {CommentHeader()}
      <Typography
        variant="body2"
        color="text.primary"
        style={{whiteSpace: 'pre-line'}} // Pour afficher les sauts de ligne
        sx={{
          margin : 1,
          overflowWrap : "break-word"
        }}
      >
        {props.comment.commentText}
      </Typography>
      {LikeButton()}
      </Box>
  )

}
