import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import Fab from '@mui/material/Fab';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ForumIcon from '@mui/icons-material/Forum';
import Box from '@mui/material/Box';

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

// Keyframes pour les animations
import { keyframes } from '@emotion/react'

export default function TutorialMap1(props) {
  //
  // Animations standard
  //

  let buttonEffect = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }`

  
  const navigate = useNavigate()

  function PreviousButton() {
    return (
      <Button
        onClick={() => navigate ("/TutorialIntro3", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function NextButton() {
    return (
      <Button
        onClick={() => props.goToTutorialPageFunction({page : "Map2"})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return(
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          <Typography variant = "h6" color={"primary"} paragraph={true}>
            Votre point de départ : la carte !
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            Touchez les spots des ports
            <Fab color="white"
              sx={{
                marginRight : 2,
                animation: `${buttonEffect} 0.5s ease infinite`
              }}
            >
              <Box 
                component="img"
                sx={{ height: 60, width: 60}}
                src={"/static/Mark.svg"}
              />
            </Fab>
            et des mouillages
            <Fab color="white"
              sx={{
                marginRight : 2,
                animation: `${buttonEffect} 0.5s ease infinite`
              }}
            >
              <Box 
                component="img"
                sx={{ height: 60, width: 60}}
                src={"/static/Mark.svg"}
              />
            </Fab>
             pour voir les conversations et participer.
          </Typography>
          <Typography color={"primary"}  paragraph={true}>
          <Fab color="white"
              sx={{
                marginRight : 2,
                animation: `${buttonEffect} 0.5s ease infinite`
              }}
            >
            <FavoriteIcon sx={{fontSize : 40, color : "#f3722C"}}/>
            </Fab>
            Mettez des spots en favoris pour recevoir des notifications et les suivre dans votre fil d'actualité.
          </Typography>
          <Typography color={"primary"}  paragraph={true}>
          <Fab color="white"
              sx={{
                marginRight : 2,
                animation: `${buttonEffect} 0.5s ease infinite`
              }}
            >
            <FavoriteIcon sx={{fontSize : 40, color : "secondary"}}/>
            </Fab>
            Cliquez sur les autres membres pour discuter avec eux.
          </Typography>
          <Typography color={"primary"}  paragraph={true}>
            <Fab color="secondary"
              sx={{
                marginRight : 2,
                animation: `${buttonEffect} 0.5s ease infinite`
              }}
            >
              <ForumIcon sx={{fontSize : 40, color : "white"}}/>
            </Fab>
            Visualisez en un coup d'oeil toutes les conversations de la zone.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}