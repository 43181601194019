import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

export default function TutorialIntro2() {

  const navigate = useNavigate()

  function PreviousButton() {
    return (
      <Button
        onClick={() => navigate ("/TutorialIntro1", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function NextButton() {
    return (
      <Button
      onClick={() => navigate ("/TutorialIntro3", {replace : true})}
      variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogTitle color={"primary"}>
        Au mouillage, en navigation, discutez enfin avec les autres bateaux !
        </DialogTitle>
        <Box sx={{display : "flex", flexDirection : "row", width : "100%", marginTop : 2}} justifyContent="flex-start">
          <Box sx={{display : "flex", flexDirection : "column", width : "80%"}} alignItems="flex-start">
            <Box bgcolor="#277DA1" sx={{ display: 'inline-flex', borderRadius : 2 }}> 
              <Typography style={{whiteSpace: 'pre-line'}} padding = {1} color = "white">
                Vous savez si les calanques sont abritées du vent cette nuit ?
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{display : "flex", flexDirection : "row", width : "100%", marginTop : 2}} justifyContent="flex-end">
          <Box sx={{display : "flex", flexDirection : "column", width : "80%"}} alignItems="flex-end">
            <Box bgcolor="#f3722C" sx={{ display: 'inline-flex', borderRadius : 2 }}> 
              <Typography style={{whiteSpace: 'pre-line'}} padding = {1} color = "white">
                Je prévois de venir mouiller ce soir, il n'y a pas trop de houle ?
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{display : "flex", flexDirection : "row", width : "100%", marginTop : 2}} justifyContent="flex-start">
          <Box sx={{display : "flex", flexDirection : "column", width : "80%"}} alignItems="flex-start">
            <Box bgcolor="#277DA1" sx={{ display: 'inline-flex', borderRadius : 2 }}> 
              <Typography style={{whiteSpace: 'pre-line'}} padding = {1} color = "white">
                Qui aurait un tire-bouchon à me prêter ? Je viens le chercher en annexe !
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}