import { useSelector, useDispatch } from 'react-redux'
import {useState, useEffect} from 'react'

import GetFunction from '../API/getFunction';
import PatchFunction from '../API/patchFunction';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Paper from '@mui/material/Paper';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Fade from '@mui/material/Fade';

import dayjs from 'dayjs'
import  'dayjs/locale/fr'
import 'dayjs/plugin/relativeTime'

import { useNavigate } from 'react-router-dom'

export default function NotificationList() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectNotificationArray = state => state.notificationArray
  const notificationArray = useSelector(selectNotificationArray)
  
  // state du contenu des notification
  const [notificationList, setNotificationList] = useState()

  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('NotificationList.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "HIDE_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('NotificationList.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])
 
  
  // Hook qui contruit le contenu de la liste à chaque fois que le contenu des notifications change
  // (améliore les performances : évite de recalculer le contenu de la liste à chaque render,
  // car il y a bcp de render avec le PullToRefresh)
  useEffect(() => {
    if (notificationArray) {
      setNotificationList(
        <List dense>
          {notificationArray.map((item, index) =>
            <ListItemButton onClick={() => OnNotificationClick(item)} divider alignItems="flex-start" key={item.id.toString()} sx={{backgroundColor : (index % 2 === 1 ? "white" : "#fdf0e9")}}>
              <ListItemAvatar>
                {NotificationAvatar(item)}
              </ListItemAvatar>
              <ListItemText
                primary={NotificationText(item)}
                secondary={DateFromNow(item)}
              />
            </ListItemButton>
          )}
        </List>
      )
    } else {
      setNotificationList(
        <Typography fontSize={"x-large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
          Pas de notification
        </Typography>
      )
    }
  }, [notificationArray])

  // pull-to-refresh : states pour conserver le point de départ + conserver la distance de pull
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState();
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Fonction pour forcer le refresh
  const RefreshNotificationList = () => {
    // Récupération du nouveau chatHeadersArray
    setIsRefreshing(true)
    GetFunction({fetchTarget : 'notificationArray', fetchArgument : null, token : token})
    .then(response => {
      setStartPoint(0);
      setPullChange(0);
      setIsRefreshing(false)
      if (response.fetchStatus === 'Ok') {
        console.log('NotificationList.js -> Fin chargement API mise à jour notificationArray')
        console.log('NotificationList.js -> Chargement notificationArray dans le state Redux')
        dispatch({ type : "LOAD_NOTIFICATION_ARRAY", payload : response.data})
      } else {
        console.log('NotificationList.js -> Erreur du fetch : on ne fait rien')
      }
    })
  }
  
  // Fonction pour gérer le pullStart
  const pullStart = (e) => {
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
    console.log('PULLSTART')
    console.log(screenY)
  };
  
  // Fonction pour gérer le pull
  const pull = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = (startPoint < screenY ? Math.abs(screenY - startPoint) : 0);
    setPullChange(pullLength);
    console.log('PULL')
    console.log(pullLength)
  };

  // Fonction pour gérer le endPull
  const endPull = (e) => {
    if (pullChange > 220) {
      RefreshNotificationList()
    } else {
      setStartPoint(0);
      setPullChange(0);
    }
    console.log('ENDPULL')
    console.log(pullChange)
  };
  
  // Mise en place et suppression des event listeners pour gérer le pull-to-refresh
  useEffect(() => {
    window.addEventListener("touchstart", pullStart); // L'écran est touché
    window.addEventListener("touchmove", pull);       // Mouvement du doigt
    window.addEventListener("touchend", endPull);     // L'écran est lâché
    return () => {
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  });
 
  function OnNotificationClick(item) {
    let notificationUpdate = {
      notificationRead: true,
    }
    PatchFunction({fetchTarget : 'notificationsviewed', fetchObjectId:item.id, fetchArgument : notificationUpdate, token : token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('NotificationList.js -> Fin chargement API notificationsviewed')
        console.log('NotificationList.js -> Mise à jour du statut Redux')
        GetFunction({fetchTarget : 'notificationArray', fetchArgument : null, token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('NotificationList.js -> Chargement notificationsArray dans le state Redux')
            dispatch({ type : "LOAD_NOTIFICATION_ARRAY", payload : response.data})
          } else {
            console.log('NotificationList.js -> Impossible de mettre à jour les pastilles de notification')
            let errorMessage = "Impossible de mettre à jour la liste de vos messages, vérifiez votre connexion"
            dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
}
        })
      } else {
        console.log('NotificationList.js -> Impossible de mettre à jour les pastilles de notification')
        let errorMessage = "Impossible de mettre à jour la liste de vos messages, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
    let nextPage = "/Comments/" + item.notificationPost 
    navigate (nextPage)
  }

  function DateFromNow(item) {
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    return(dayjs(item.notificationTimestamp,"YYYY-MM-DD HH:mm:ss").fromNow())
  }

  function NotificationText(item) {
    let notificationText = ""
    if (item.notificationType === "PostInSubscribedSpot") {
      notificationText =item.authorNickname + ' a publié dans un lieu que vous suivez'
    } else if (item.notificationType === "CommentOnMyPost") {
      notificationText = item.authorNickname + ' a commenté une de vos publications.'
    } else if (item.notificationType === "LikeOnMyPost") {
      notificationText = item.authorNickname + ' a aimé une de vos publications.'
    } else if (item.notificationType === "LikeOnMyComment") {
      notificationText = item.authorNickname + ' a aimé un de vos commentaires.'
    } else if (item.notificationType === "CommentOnMyComment") {
      notificationText = item.authorNickname + ' a commenté un post que vous avez commenté.'
    } else if (item.notificationType === "NearbyMark") {
      notificationText = item.authorNickname + ' a signalé un évènement proche de vous.'
    } else if (item.notificationType === "NearbyPost") {
      notificationText = item.authorNickname + ' a publié dans un lieu proche de vous.'
    }
    
    if (item.notificationRead === false) {
      return(
        <Typography fontWeight={'bold'}>
          {notificationText}
        </Typography>
      )
    } else {
      return(
        <Typography>
          {notificationText}
        </Typography>
      )
    }
  }

  function NotificationAvatar(item) {
    if (item.authorAvatarurl) {
      return (
        <Avatar
          src={item.authorAvatarurl}  
          sx={{ width: 50, height: 50 }}
        />
      )
    } else {
      return (
        <AccountCircleIcon color="primary" aria-label="upload picture" sx={{ fontSize: 50 }} />
      );
    }
  };
  
  return (
    <Fade in={true} timeout={200}>
      <Box sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0}}>
        {/* Fond grisé qui renvoie vers la page de la carte*/}
        <Box
          onClick = {() => {navigate("/MapPage")}} 
          sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, backgroundColor : "grey", opacity : 0.5}}
        />
        <Paper sx={{position : "absolute", top : {xs :80, md : 90}, bottom : 20, left : 20, right : 20, backgroundColor : "white", borderRadius : 1}} elevation={24}>
          {/* Croix pour fermer la fenêtre */}
          <Box sx={{position : "absolute", top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {() => {navigate("/MapPage")}}>
            <IconButton color="secondary">
              <HighlightOffIcon fontSize = "large"/>
            </IconButton>
          </Box>
          <Box sx={{
            display : "flex",
            flexDirection : "column",
            height : "100%",
            width : "100%"
            }}
          >
            <Container sx={{display : "flex", height : Math.min(pullChange,50+Math.max(0,pullChange-50)/5)}}>
            <Box sx={{ flex : 1}}/>
              {(isRefreshing || pullChange > 100) ? <CircularProgress/>:null}
              <Box sx={{ flex : 1}}/>
            </Container>
            <Box sx={{display : "flex", width : "100%", margin : 2}}>
              <Typography variant="h5" fontWeight = "bold" color="primary">
                Mes notifications
              </Typography>
            </Box>
            <Box sx={{display : "flex", flexDirection : "column", width : "100%"}} overflow = "auto">
              {notificationList}
            </Box>
          </Box>
        </Paper> 
      </Box>
    </Fade>
  )
}