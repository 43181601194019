import {useState, useEffect} from 'react'
import GetFunction from '../API/getFunction'

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import GroupsIcon from '@mui/icons-material/Groups';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

import BackButton from '../Navigation/BackButton';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';


// DECLARATIONS REACT ROUTER
import { useLocation, useNavigate } from 'react-router-dom'

// DECLARATIONS STORE
import { useSelector, useDispatch } from 'react-redux'


export default function FeedMembers() {

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const [feedMembersState, setFeedMembersState] = useState({
    feedMembers: [],
    isFetching: true
  })


  const navigate = useNavigate()
  const dispatch = useDispatch()

    // On récupère le spot passés en state du lien
    const {state} = useLocation();
    const { spot } = state
  
    // pull-to-refresh : states pour conserver le point de départ + conserver la distance de pull
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState();
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Fonction pour forcer le refresh
  const RefreshMemberList = () => {
    // Récupération du nouveau post
    setIsRefreshing(true)
    GetFunction({fetchTarget : 'getFeedMembers', fetchArgument : spot.pk, token : token})
    .then(response => {
      setStartPoint(0);
      setPullChange(0);
      setIsRefreshing(false)
      if(response.fetchStatus === 'Ok') {
        console.log('FeedMembers.js -> Fin chargement API getFeedMembers')
        console.log('FeedMembers.js -> Mise à jour du statut getFeedMembers')
        setFeedMembersState(prevState => ({...prevState, feedMembers:response.data}))
      } else {
        console.log('FeedMembers.js -> Impossible de charger la liste des abonnés')
      }
    })
  };

  // Fonction pour gérer le pullStart
  const pullStart = (e) => {
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
  };

  // Fonction pour gérer le pull
  const pull = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
    setPullChange(pullLength);
  };

  // Fonction pour gérer le endPull
  const endPull = (e) => {

    if (pullChange > 220) {
      RefreshMemberList()
    } else {
      setStartPoint(0);
      setPullChange(0);
    }
  };

  // Mise en place et suppression des event listeners pour gérer le pull-to-refresh
  useEffect(() => {
    window.addEventListener("touchstart", pullStart); // L'écran est touché
    window.addEventListener("touchmove", pull);       // Mouvement du doigt
    window.addEventListener("touchend", endPull);     // L'écran est lâché
    return () => {
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  });

// Chargement de la liste des membres, lancé dans useEffect
  function LoadFeedMembers() {
    console.log('FeedMembers.js -> Chargement API getFeedMembers')
    GetFunction({fetchTarget : 'getFeedMembers', fetchArgument : spot.pk, token : token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('FeedMembers.js -> Fin chargement API getFeedMembers')
        console.log('FeedMembers.js -> Mise à jour du statut getFeedMembers')
        console.log(response.data)
        setFeedMembersState(prevState => ({...prevState, feedMembers:response.data, isFetching:false}))
      } else {
        console.log('FeedMembers.js -> Impossible de charger la liste des abonnés')
        setFeedMembersState(prevState => ({...prevState, isFetching:false}))

        let errorMessage = "Impossible de charger la liste des abonnés du lieu, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function  ViewProfile(userId) {
    navigate("/OtherUserProfile/" + userId)   
  }

  function NbSubscribers() {
    if (feedMembersState.feedMembers.length !== 0) {
      return(feedMembersState.feedMembers.length)
    } else {
      return (0)
    }
  }

  function UserAvatar(item) {
    if (item.userAvatarurl) {
      return (
        <Avatar
          src={item.userAvatarurl}  
          sx={{ width: 30, height: 30 }}
        />
      )
    } else {
      return (
        <AccountCircleIcon color="primary" aria-label="upload picture" sx={{ fontSize: 30 }} />
      );
    }
  };

  function UserGlobalBadge(item) {
    if (item.userGlobalBadge) {
    return (
      <Avatar
      src={item.userGlobalBadge}  
      sx={{ width: 15, height: 15 }}
    />
      )
    } else {
    return null;
    }
  };

  useEffect(() => {
    console.log('FeedMembers.js -> useEffect')
    LoadFeedMembers()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   


  function FeedMembersListContent() {
    if (!feedMembersState.isFetching) {
      if (feedMembersState.feedMembers) {
        console.log(feedMembersState.feedMembers)
        return(
          <List sx={{width : "100%"}}>
            {feedMembersState.feedMembers.map(item =>
            <ListItem disablePadding alignItems="flex-start" key={item.pk.toString()} >
              <ListItemButton
                sx={{
                  backgroundColor: "white",
                }}
                onClick={() => ViewProfile(item.pk)}
              >
                <ListItemAvatar>
                  {UserAvatar(item)}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box sx={{display : "flex", alignItems : "center"}}>
                      {item.userNickname}{UserGlobalBadge(item)}
                    </Box>
                  }
                  secondary={item.userBoat}
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>
        )
      } else {
        return(
          <Typography fontSize={"x-large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
            Pas encore d'utilisateur abonné
          </Typography>
        )
      }
    } else {
      return(
        <Box sx={{ display : "flex", flexDirection : "column"}}>
          <Skeleton variant="rounded" width={"90%%"} height={20} sx={{padding : 3, margin : 3}}/>
          <Skeleton variant="rounded" width={"90%%"} height={100} sx={{padding : 3, margin : 3}}/>
          <Skeleton variant="rounded" width={"90%%"} height={100} sx={{padding : 3, margin : 3}}/>
        </Box>
      )
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <Grow in={true} direction="up">
        <Paper elevation={10}>
          <Box
            sx={{
              marginTop: 5,
              marginRight: 1,
              marginLeft: 1,
              marginBottom : 5,
              paddingTop: 2,
              paddingRight: 2,
              paddingLeft: 2,
              paddingBottom : 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor : "white",
              borderRadius : 3
            }}
          >
            <BackButton/>
            <Container sx={{display : "flex", height : Math.min(pullChange,50+Math.max(0,pullChange-50)/5)}}>
              <Box sx={{ flex : 1}}/>
              {(isRefreshing || pullChange > 100) ? <CircularProgress/>:null}
              <Box sx={{ flex : 1}}/>
            </Container>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography sx={{fontWeight : "bold",fontSize : {xs : 20, sm : 22, md : 24}, marginBottom : 2}} color={"primary"}>
                {spot.spotName}
              </Typography>
              <Chip color="primary" icon={<GroupsIcon/>} label={NbSubscribers()}/>
            </Box>
            {FeedMembersListContent()}
          </Box>
        </Paper>
      </Grow>
    </Container>
  )
}
