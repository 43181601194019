import { useEffect, useState, Fragment } from "react";

// Keyframes pour les animations
import { keyframes } from '@emotion/react'

// dayjs permet de transformer les formats de date et de faire des calculs
import * as dayjs from 'dayjs'
import  'dayjs/locale/fr'
import 'dayjs/plugin/relativeTime'

// Fonction, stockée dans un fichier à part, qui renvoie les types de ReportMark possibles
import ReportTypesArray from "../Mark/ReportTypesArray";

import PostPreview from "./VisiblePostPreview"
import SpotPostPreview from "./SpotPostPreview"

// Déclarations de mise en forme MUI
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import IconButton from '@mui/material/IconButton';
import FaceIcon from '@mui/icons-material/Face';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Paper from '@mui/material/Paper';
import ForumIcon from '@mui/icons-material/Forum';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import CircularProgress from '@mui/material/CircularProgress';

import Chip from '@mui/material/Chip'

import SendIcon from '@mui/icons-material/Send';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import Fab from '@mui/material/Fab';

import PostFunction from "../API/postFunction";
import GetFunction from "../API/getFunction";
import DeleteFunction from "../API/deleteFunction";

import TutorialMap1 from "../Tutorial/TutorialMap1"
import TutorialMap2 from "../Tutorial/TutorialMap2"
import TutorialMap3 from "../Tutorial/TutorialMap3"

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'


//
// DECLARATION DES VARIABLES
// A toujours laisser avant le export default de début de la fonction du component (sinon, ça ne marche pas)
//

// Variables pour la création de polygones
let currentPolyline = null
let currentPolygon = null
let currentPolygonArray = []
let newPolygonIsClosed = false;
let newPolygonMarkersArray = []

let polygonOptionsNormal = {
  clickable : true,
  strokeColor: "#277DA1",
  strokeOpacity: 0.3,
  strokeWeight: 2,
  fillColor: "#277DA1",
  fillOpacity: 0.2
}
let polygonOptionsSelected = {
  clickable : true,
  strokeColor: "#277DA1",
  strokeOpacity: 0.6,
  strokeWeight: 3,
  fillColor: "#277DA1",
  fillOpacity: 0.4
}
let polygonOptionsMultipost = {
  clickable : true,
  strokeColor: "#43AA8B",
  strokeOpacity: 0.6,
  strokeWeight: 3,
  fillColor: "#43AA8B",
  fillOpacity: 0.4
}
let polygonOptionsDesactivated = {
  clickable : false,
  strokeColor: "grey",
  strokeOpacity: 0.1,
  strokeWeight: 1,
  fillColor: "grey",
  fillOpacity: 0.1
}

// On initialise les variables utilisées pour créer les markers
let markerIcon 
let markerImgZoom = 12

export default function MapFixedPage() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

// Récupération du state transmis par Navigate, qui contient un spot si on vient de SearchField ou de SubscribedSpots, ou un paramètre spécial si on fait un post  depuis le newsfeed
  const {state} = useLocation();
  let zoomToSearch = null
  let zoomToSearchDone = null
  let newsFeedMultiplePost = false
  if (state) {
    if (state.zoomToSearch) {
      zoomToSearch = state.zoomToSearch
    }
    if (state.newsFeedMultiplePost) {
      newsFeedMultiplePost = true
    }
  }

  // Récupération du contenu du fichier externe
  const reportTypesArray = ReportTypesArray()

  // Fonction utilisée pour calculer l'expiration des reportMarks à afficher
  dayjs.locale('fr')
  function DateFromNow(props) {
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    return(dayjs(props,"YYYY-MM-DD HH:mm:ss").fromNow())
  }

  // Fonction qui renvoie si la date + la durée d'expiration est passée ou pas
  function IsNotExpired(props) {
    var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
    dayjs.extend(isSameOrAfter)
    let expiration = dayjs(props.timestamp).add(props.duration,'h')
    return(dayjs(expiration).isSameOrAfter(dayjs()))
  }

  //
  // CONNEXION DES STATES DU STORE
  //

  const selectMarkersArray = state => state.markersArray
  const markersArray = useSelector(selectMarkersArray)

  const selectUserPositionsArray = state => state.userPositionsArray
  const userPositionsArray = useSelector(selectUserPositionsArray)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectSubscriptionsArray = state => state.subscriptionsArray
  const subscriptionsArray = useSelector(selectSubscriptionsArray)

  const selectReportMarksArray = state => state.reportMarksArray
  const reportMarksArray = useSelector(selectReportMarksArray)

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const selectChatHeadersArray = state => state.chatHeadersArray
  const chatHeadersArray = useSelector(selectChatHeadersArray)

  // State du store permettant d'afficher ou pas le component
  const selectDisplayMap = state => state.displayMap
  const displayMap = useSelector(selectDisplayMap)

  // State du store permettant d'afficher ou pas les µFABs
  const selectDisplayFABs = state => state.displayFABs
  const displayFABs = useSelector(selectDisplayFABs)

  //
  // DECLARATION DES STATES
  //
  
  // Progression dans le tutoriel
  const [tutorialState, setTutorialState] = useState(null)

  // Stockage d'une instance de la carte, pour pouvoir l'utiliser comme objet
  const [map, setMap] = useState(null)

  // States modifiés par les listeners de la map, et qui servent à déclencher des actions via des useEffect
  const [idleMapState, setIdleMapState] = useState(false)
  const [mapClickedState, setMapClickedState] = useState(false)
  const [dragMapState, setDragMapState] = useState(false)

  // Marker cliqué,  sélectionné et  précédent sélectionné
  const [clickedMarker, setClickedMarker] = useState(null)
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [previousSelectedMarker, setPreviousSelectedMarker] = useState(null);

  // Stockage des références des markers de la map
  const [markerIDsArray, setMarkerIDsArray] = useState([])
  const [polygonIDsArray, setPolygonIDsArray] = useState([])
  const [userPositionIDsArray, setUserPositionIDsArray] = useState([])
  const [reportMarkIDsArray, setReportMarkIDsArray] = useState([])
  const [newReportMarkID, setNewReportMarkID] = useState(null)
  const [newSpotMarkID, setNewSpotMarkID] = useState(null)
  // const [infowindowIDsarray, setInfowindowIDsarray] = useState([])

  // State permettant d'afficher l'icône correspondant à l'état de subscription (vrai ou faux)
  const [selectedMarkerSubscribed, setSelectedMarkerSubscribed] = useState(false)

  // State permettant de gérer les subscription en cours de fetch
  const [isSubscribing, setIsSubscribing] = useState(null)

  // Gestion des fonctions accessibles sur la map (création messsage pour spots multiples, création marker, création spot / polygone par l'admin)
  const [newContentState, setNewContentState] = useState({
    isSelectingSpotsForMultipost : false,
    selectedSpots : [],
    isSettingNewReportMark : false,
    newReportMark : null,
    isSettingNewSpot : false,
    newSpotPolygonJSON :null,
    newSpotMarker : null,
    isSharingPosition : null
  })

  // States de gestion des posts des spots visibles
  const [visibleSpotsState, setVisibleSpotsState] = useState({
    spotsIDsString : "",
    spotsIDsArray : [],
    postsArray : [],
    previousPageLink : null,
    nextPageLink : null,
    isFetchingFeed : false,
    displayFeed : false,
    feed:null
  })

  // State de gestion du preview du spot
  const [spotFeedPreview, setSpotFeedPreview] = useState({
    isFetching : false,
    feedPreviewContent : null
  })

  // State de gestion de la position courante
  const[userPositionID, setUserPositionID] = useState(null)

  //
  // Animations standard
  //

  let buttonEffect = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }`

  // 
  // CREATION ET INITIALISATION DE LA MAP GOOGLE
  //

  // Taille des pin
  let pinWidth = 45
  let pinHeight = 45
  let userPinWidth = 25
  let userPinHeight = 25

  // Nb maximum de spots où on peut faire un multiPost
  let multipostMaxSpots = 10

  // On positionne le centre de la carte et le zoom par défaut
  let center = {
      lat: 43.3318,
      lng: 5.0550
  }
  let zoom = 15

  // On récupère le center de la carte dans les variables locales
  let centerLocalData = localStorage.getItem('NAUTILUS_CENTER')
  if (typeof centerLocalData !== 'undefined') {
    let localCenter = JSON.parse(centerLocalData);
    if (localCenter) {
      if (typeof(localCenter) == "object" && typeof(localCenter.lat) == "number" && typeof(localCenter.lng) == "number") {
        center = localCenter
      }
    } 
  }

  // On récupère le zoom de la carte dans les variables locales
  let zoomLocalData = localStorage.getItem('NAUTILUS_ZOOM')
  if (typeof zoomLocalData !== 'undefined') {
    let localZoom = JSON.parse(zoomLocalData);
    if (localZoom) {
      if (typeof(localZoom) == "number") {
        zoom = localZoom
      } 
    }
  }

  // Options de la map
  const myMapStyles = [
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        { color: '#66b8da' }
      ]
    },{
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        { color: '#ff8500' }
      ]
    },{
      featureType: 'administrative',
      elementType: 'labels.text.stroke',
      stylers: [
        { color: '#ffffff' },
        { weight: 6 }
      ]
    },{
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        { lightness: 100 }
      ]
    },{
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        { visibility: 'off' },
      ]
    }
  ];

  let mapOptions = {
    center: center,
    zoom: zoom,
    styles : myMapStyles,
    disableDefaultUI : true,
    clickableIcons : false,
  }

  // Création et d'initialisation de la map

  async function initMap() {
    // Création de la map
    const { Map } = await window.google.maps.importLibrary("maps");
    let map = new Map(window.document.getElementById("map"), mapOptions);

    // Création des listeners de click et idle
    // Attention : les variables dans ces fonctions ne changent pas de valeur (elles sont envoyées une seule fois, lors de la création du listener) :
    // Toujours leur faire modifier un state, et lancer des actions dans un hook qui surveille ce state
    map.addListener("click", (event) => {
      setMapClickedState(event)
    });
    map.addListener("idle", (event) => {
      setIdleMapState(true)
    });
    map.addListener("dragstart", (event) => {
      setDragMapState(true)
    });

    // On crée une polyline, qui deviendra le polygone quand elle sera bouclée
    currentPolyline = new window.google.maps.Polyline({ map: map, path: [], strokeColor: "#FF0000", strokeOpacity: 1.0, strokeWeight: 2 });

    // Stockage d'une instance de la map dans le state map
    setMap(map)
  }

  // Hook qui lance la création de la map à chaque nouveau mount (il y a nouveau mount au login et au logout)
  useEffect(() => {
    initMap()
  },[])

  // Hook déclenché par le listener "idle" de la map
  useEffect(()=> {
    if (idleMapState) {
      IdleMap()
      setIdleMapState(false)
    }
  },[idleMapState])

  // Hook déclenché par le listener "click" de la map
  useEffect(()=> {
    if (mapClickedState) {
      HandleMapClick(mapClickedState)
      setMapClickedState(false)
    }
  },[mapClickedState])
  
  // Hook déclenché par le listener "dragstart" de la map
  useEffect(()=> {
    if (dragMapState) {
      HandleMapDrag(dragMapState)
      setDragMapState(false)
    }
  },[dragMapState])
  
  
  //
  // TUTORIEL
  //

  // Lancement du tutoriel si c'est demandé dans le state
  function GoToTutorialPage(props){
    setTutorialState(props.page)
  }

  function TutorialScreens() {
    if (tutorialState === "Map1") {
      return(
        <TutorialMap1 goToTutorialPageFunction = {GoToTutorialPage}/>
      )
    } else if (tutorialState === "Map2") {
      return(
        <TutorialMap2 goToTutorialPageFunction = {GoToTutorialPage}/>
      )
    } else if (tutorialState === "Map3") {
      return(
        <TutorialMap3 goToTutorialPageFunction = {GoToTutorialPage}/>
      )
    } else if (tutorialState === "Close") {
      // On n'affiche rien, mais on remplace dans l'historique l'adresse de la page par la même
      // mais sans le state de lancement du tutoriel. Sinon, si on remonte dans l'historique, 
      // ça relance le tutoriel
      setTutorialState(null)
      navigate("/MapPage", {replace : true})
    } else return (null)
  }

  // Lancement automatique de la page du tutoriel si on affiche la carte en provenant de la page 2 du tutoriel, qui passe l'informatino dans le state
  useEffect(()=> {
    if (map) {
      if (state) {
        if (state.tutorial) {
          setTutorialState("Map1")
        }
      }
    }
  },[map, state])


  // 
  // HOOKS D'AFFICHAGE DU CONTENU DE LA MAP
  //

  // Hook qui crée les markers quand la map est créée ou que le markersArray change
  useEffect(() => {
    if (map) {
      if (markersArray) {
        // On supprime les markers et polygones éventuellement existants de la carte
        markerIDsArray.forEach(marker => {
          marker.setMap(null)
        })
        polygonIDsArray.forEach(polygon => {
          polygon.setMap(null)
        })
        let newMarkersIDsArray = []
        let newPolygonsIDsArray = []
        markersArray.forEach((marker, index) => {
          if (token) {
            if (subscriptionsArray.findIndex(id => (marker.pk === id.subscriptionSpot)) !== -1) {
              if (marker.spotType === "Mouillage") {
                markerIcon = {url : "/static/Mouillage-Subscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
              } else {
                markerIcon = {url : "/static/Port-Subscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
              }  
            } else {
              if (marker.spotType === "Mouillage") {
                markerIcon = {url : "/static/Mouillage-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
              } else {
                markerIcon = {url : "/static/Port-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
              }  
            }
          } else {
            if (marker.spotType === "Mouillage") {
              markerIcon = {url : "/static/Mouillage-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
            } else {
              markerIcon = {url : "/static/Port-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
            }  
          }
          let markerOptions = {
            map: map,
            position: {lat:marker.spotLatitude, lng:marker.spotLongitude},
            icon : markerIcon,
            title : marker.spotName,
          };
          // Création du marker et stockage de sa référence dans le tableau
          newMarkersIDsArray[index] = new window.google.maps.Marker(markerOptions);
          // Ajout du listener de click
          newMarkersIDsArray[index].addListener("click", () => {
            OnMarkerClicked({marker : marker})
          })

          // Création du polygone clair et stockage de sa référence dans le tableau
          newPolygonsIDsArray[index] = new window.google.maps.Polygon({...polygonOptionsNormal, map : map, paths: JSON.parse(marker.spotPolygon)})
          // Ajout du listener de click
          newPolygonsIDsArray[index].addListener("click", () => {
            OnMarkerClicked({marker : marker})
          })

        })
        // Stockage des références des markers et des polygons dans un state
        setMarkerIDsArray(newMarkersIDsArray)
        setPolygonIDsArray(newPolygonsIDsArray)
      }
    }
  }, [map,markersArray])

  function OnMarkerClicked(props) {
    // Fermeture de l'affichage du visible SpotsFeed
    setVisibleSpotsState(prevState => ({...prevState, displayFeed : false }))

    // Si on n'est pas en traind de creér un nouveau spot, on déclenche l'action suivente en positionnant le marqueur cliqué, ce qui déclenchera le hook qui suit
    if (newContentState.isSettingNewSpot === false) {
    setClickedMarker(props.marker)
    }
  }

  // Hook qui met à jour les icônes des markers à chaque fois que les subscriptions changent 
  useEffect(() => {
    if (map) {
      if (markerIDsArray) {
        markersArray.forEach((marker, index) => {
          if (token) {
            if (subscriptionsArray.findIndex(id => (marker.pk === id.subscriptionSpot)) !== -1) {
              if (marker.spotType === "Mouillage") {
                markerIcon = {url : "/static/Mouillage-Subscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
              } else {
                markerIcon = {url : "/static/Port-Subscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
              }  
            } else {
              if (marker.spotType === "Mouillage") {
                markerIcon = {url : "/static/Mouillage-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
              } else {
                markerIcon = {url : "/static/Port-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
              }  
            }
          } else {
            if (marker.spotType === "Mouillage") {
              markerIcon = {url : "/static/Mouillage-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
            } else {
              markerIcon = {url : "/static/Port-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
            }  
          }
          markerIDsArray[index].setIcon(markerIcon)
        });
      }
    }
  }, [subscriptionsArray,markerIDsArray])

  
  // Hook qui crée les reportMarks quand la map est créée ou que le reportMarkersArray change
  useEffect(() => {
    if (map) {
      if (reportMarksArray) {
        // Suppression des reportMarks déjà affichés
        reportMarkIDsArray.forEach(marker => {
          marker.setMap(null)
        })
        let newReportMarksIDsArray = []
        reportMarksArray.forEach((marker, index) => {
          let markerTypeObject = reportTypesArray.find(item => (item.type === marker.reportMarkType))
          // On n'affiche que les reportMarks qui ne sont pas expirés
          let markerDuration = markerTypeObject.hours
          if (IsNotExpired({timestamp : marker.reportMarkTimestamp, duration : markerDuration})) {
            let markerIcon = markerTypeObject.icon
            let markerOption = {
              map: map,
              position: {lat:marker.reportMarkLatitude, lng:marker.reportMarkLongitude},
              icon : { url : markerIcon, scaledSize : {width:pinWidth, height:pinHeight}},
              title : markerTypeObject.type,
            };
            // Création du reportMark et stockage de sa référence dans le tableau
            newReportMarksIDsArray[index] = new window.google.maps.Marker(markerOption);
            // Ajout du listener de click
            newReportMarksIDsArray[index].addListener("click", () => {
              OnMarkerClicked({marker : marker})
            })
          }
        })
        // Stockage des références des reportMark dans un state
        setReportMarkIDsArray(newReportMarksIDsArray)
      }
    }
  }, [map,reportMarksArray])


    // Hook qui crée les userPositions quand la map est créée ou que le userPositionsArray change
  // Prend aussi en compte le paramètre userPosition Share
  useEffect(() => {
    if (map) {
      if (userPositionsArray) {
        // On supprime les markers éventuellement existants de la carte
        userPositionIDsArray.forEach(marker => {
          marker.setMap(null)
        })
        // Si userPositionShare est activé : on affiche les positions sur la carte
        if (myProfile.userPositionShare) {
          let newUserPositionIDsArray = []
          userPositionsArray.forEach((marker, index) => {
            if (marker.userPositionUser === myProfile.pk) {
              markerIcon = {url : marker.userPositionMapIcon.substr(0,marker.userPositionMapIcon.length-4)+"-me.svg", scaledSize : {width:userPinWidth, height:userPinHeight}, anchor : new window.google.maps.Point(userPinWidth/2,userPinHeight/2)}
            } else {
              markerIcon = {url : marker.userPositionMapIcon, scaledSize : {width:userPinWidth, height:userPinHeight}, anchor : new window.google.maps.Point(userPinWidth/2,userPinHeight/2)}
            }
            let markerOption = {
              map: map,
              position: {lat:marker.userPositionLatitude, lng:marker.userPositionLongitude},
              icon : markerIcon,
              title : marker.userPositionNickname,
              zIndex : 1000
            };
            // Création du marker et stockage de sa référence dans le tableau
            newUserPositionIDsArray[index] = new window.google.maps.Marker(markerOption);
            // Ajout du listener de click, selon que l'utilisateur est connecté ou pas
            if (token) {
              newUserPositionIDsArray[index].addListener("click", () => {
                OnMarkerClicked({marker : marker})
              })
            } else {
              newUserPositionIDsArray[index].addListener("click", () => {
                navigate("/login")
              })
            }
          })
          // Stockage des références des markers dans un state
          setUserPositionIDsArray(newUserPositionIDsArray)
        }
      }
    }
  }, [map,userPositionsArray, myProfile.userPositionShare])

  //
  // TRAITEMENT DU CLICK SUR LA MAP
  //

  // Hook qui lance le zoom si un spot a été trouvé dans le state du useLocation (quand on vient depûis le champs de recherche ou l'écran SubscribedSpots)
  useEffect(() => {
    if (map) {
      if (zoomToSearch) {
        ZoomToSearch()
      }
    }
  }, [map,zoomToSearch])
  
  // Hook qui lance une création de multiplePost si le paraètre a été trouvé dans le state du useLocation (quand on vient depûis le newsFeed)
  useEffect(() => {
    if (map) {
      console.log(newsFeedMultiplePost)
      if (newsFeedMultiplePost === true) {
        newsFeedMultiplePost = false
        StartSpotsSelection()
      }
    }
  }, [map,newsFeedMultiplePost])
  
  
  // Hook lancé quand on a cliqué sur un marqueur
  useEffect(() => {
    if (clickedMarker) {
      // On ferme le visibleSpotsFeed
      setVisibleSpotsState(prevState => ({...prevState, displayFeed : false }))

      // Choix de l'action en fonction du type de marker : spot, reportMark ou userPosition
      if (clickedMarker.hasOwnProperty('spotType')) { // On a cliqué sur un spot
        // Action différente selon qu'on est dans l'écran de post collectif, de signalemment ou l'écran normal
        if (newContentState.isSelectingSpotsForMultipost) {
          OnMarkerClickedForNewMultiplePost()
        } else if (newContentState.isSettingNewReportMark) {
        } else {
          OnMarkerClickedForDisplay()
        }
      } else if (clickedMarker.hasOwnProperty('reportMarkType')) { // On a cliqué sur un reportMark
        if (newContentState.isSelectingSpotsForMultipost) {
        } else if (newContentState.isSettingNewReportMark) {
        } else {
          OnReportMarkClickedForDisplay()
        }
      } else if (clickedMarker.hasOwnProperty('userPositionUser')) { // On a cliqué sur un userPosition
        if (newContentState.isSelectingSpotsForMultipost) {
        } else if (newContentState.isSettingNewReportMark) {
        } else {
          OnUserPositionClickedForDisplay()
        }
      }
    }
  }, [clickedMarker])

  function RemoveAnimationAndPolygon(props) {
    if (props.hasOwnProperty('spotType')) {
      let markerIndex = markersArray.findIndex(marker => (marker.pk === props.pk))
      markerIDsArray[markerIndex].setAnimation(null)
      polygonIDsArray[markerIndex].setOptions(polygonOptionsNormal)
    } else if (props.hasOwnProperty('reportMarkType')){
      let markerIndex = reportMarksArray.findIndex(marker => (marker.pk === props.pk))
      reportMarkIDsArray[markerIndex].setAnimation(null)
    } else if (props.hasOwnProperty('userPositionUser')){
      let markerIndex = userPositionsArray.findIndex(marker => (marker.pk === props.pk))
      userPositionIDsArray[markerIndex].setAnimation(null)
    }
  }

  function UnSelectMarker() {
    // On supprime l'animation et le polygon du marker
    RemoveAnimationAndPolygon(selectedMarker)
    setClickedMarker(null)
    setSelectedMarker(null)
    setPreviousSelectedMarker(null)
  }

  function OnMarkerClickedForDisplay() {
    // On suppprime les indicateurs d'avancement et messages issus d'un marker précédemment cliqué
    map.panTo({
      lat: clickedMarker.spotLatitude-1/Math.pow(2,(map.getZoom()-7)),
      lng: clickedMarker.spotLongitude
    })
    // On met à jour le state selectedMarker
    if (clickedMarker.spotType === "Port") {
      markerImgZoom = 15
    } else if (clickedMarker.spotType === "Mouillage") {
      markerImgZoom = 12
    }
    setSelectedMarker({...clickedMarker, markerImgZoom : markerImgZoom})
    // On met à jour le state selectedMarkerSubscribed
    if (token) {
      if (subscriptionsArray.findIndex(id => (clickedMarker.pk === id.subscriptionSpot)) !== -1) {
        setSelectedMarkerSubscribed(true)
      } else {
        setSelectedMarkerSubscribed(false)
      }
    } else {
      setSelectedMarkerSubscribed(false)
    }
    // On annule l'animation et le polygon du marker précédent éventuel
    if (previousSelectedMarker) {
      RemoveAnimationAndPolygon(previousSelectedMarker)
    }
    // On anime le marker et on rend son polygon foncé
    let markerIndex = markersArray.findIndex(marker => (marker.pk === clickedMarker.pk))
    markerIDsArray[markerIndex].setAnimation(window.google.maps.Animation.BOUNCE)

    polygonIDsArray[markerIndex].setOptions(polygonOptionsSelected)

    // On stocke le nouveau marker qui sera, la prochaine fois, le "marker précédent"
    setPreviousSelectedMarker(clickedMarker)

    // On lance la récupération du SpotFeedPreview
    LoadFeedPreviewContent(clickedMarker.pk)
  }

  // Fonction de chargement du preview du feed
  function LoadFeedPreviewContent(spotId) {
    console.log('MapFixedPage.js -> Chargement API spotFeedPreviewContent')
    setSpotFeedPreview(prevState => ({...prevState, isFetching : true})) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
    GetFunction({fetchTarget : 'spotFeedPreviewContent', fetchArgument:spotId})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('MapFixedPage.js -> Fin chargement API spotFeedPreviewContent')
        console.log('MapFixedPage.js -> Mise à jour du statut spotFeedPreview')
        setSpotFeedPreview(prevState => ({...prevState, feedPreviewContent : response.data, isFetching : false})) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
      } else {
        console.log('MapFixedPage.js -> Impossible de charger le contenu du feed')
        setSpotFeedPreview(isFetching => false) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
        let errorMessage = "Impossible de trouver les conversation de ce lieu, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }
  
  function OnMarkerClickedForNewMultiplePost() {
    // On met à jour le tableau des markers sélectionnés
    let spotIndex = newContentState.selectedSpots.findIndex(item => (item.pk === clickedMarker.pk))
    let markerIndex = markersArray.findIndex(item => (item.pk === clickedMarker.pk))
    if (spotIndex === -1) {
      if (newContentState.selectedSpots.length < multipostMaxSpots) {
        let newSelectedSpots = newContentState.selectedSpots
        newSelectedSpots.push(clickedMarker)
        setNewContentState(prevState => ({...prevState, selectedSpots : newSelectedSpots}))
        markerIDsArray[markerIndex].setAnimation(window.google.maps.Animation.BOUNCE)
        if (clickedMarker.spotType === "Mouillage") {
          markerIcon = {url : "/static/Mouillage-Multipost.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        } else {
          markerIcon = {url : "/static/Port-Multipost.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        }  
        markerIDsArray[markerIndex].setIcon(markerIcon)
        polygonIDsArray[markerIndex].setOptions(polygonOptionsMultipost)
      }
    } else {
      let selectedSpots = newContentState.selectedSpots
      let newSelectedSpots = selectedSpots.filter(item => (item.pk !== clickedMarker.pk)) // On fait le filter, et on récupère la valeur de l'élement sorti (mais on ne s'en servira pas)
      setNewContentState(prevState => ({...prevState, selectedSpots : newSelectedSpots}))
      // Restauration de l'icone d'origine
      if (token) {
        if (subscriptionsArray.findIndex(id => (clickedMarker.pk === id.subscriptionSpot)) !== -1) {
          if (clickedMarker.spotType === "Mouillage") {
            markerIcon = {url : "/static/Mouillage-Subscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
          } else {
            markerIcon = {url : "/static/Port-Subscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
          }  
        } else {
          if (clickedMarker.spotType === "Mouillage") {
            markerIcon = {url : "/static/Mouillage-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
          } else {
            markerIcon = {url : "/static/Port-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
          }  
        }
      } else {
        if (clickedMarker.spotType === "Mouillage") {
          markerIcon = {url : "/static/Mouillage-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        } else {
          markerIcon = {url : "/static/Port-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        }  
      }
      markerIDsArray[markerIndex].setIcon(markerIcon)
      markerIDsArray[markerIndex].setAnimation(null)
      polygonIDsArray[markerIndex].setOptions(polygonOptionsNormal)
    }
    setClickedMarker(null)
  }

  function OnReportMarkClickedForDisplay() {
    // On suppprime les indicateurs d'avancement et messages issus d'un marker précédemment cliqué
    map.panTo({
      lat: clickedMarker.reportMarkLatitude-1/Math.pow(2,(map.getZoom()-7)),
      lng: clickedMarker.reportMarkLongitude
    })
    let markerTypeObject = reportTypesArray.find(item => (item.type === clickedMarker.reportMarkType))
    let markerIcon = markerTypeObject.icon
    let markerLabel = markerTypeObject.label
    // On met à jour le state selectedMarker
    setSelectedMarker({...clickedMarker, icon : markerIcon, label : markerLabel})
    // On annule l'animation et le polygon du marker précédent éventuel
    if (previousSelectedMarker) {
      RemoveAnimationAndPolygon(previousSelectedMarker)
    }
    // On anime le marker
    let reportMarkIndex = reportMarksArray.findIndex(marker => (marker.pk === clickedMarker.pk))
    reportMarkIDsArray[reportMarkIndex].setAnimation(window.google.maps.Animation.BOUNCE)
    // On stocke le nouveau marker qui sera, la prochaione fois, le "marker précédent"
    setPreviousSelectedMarker(clickedMarker)
  }

  function OnUserPositionClickedForDisplay() {
    // On suppprime les indicateurs d'avancement et messages issus d'un marker précédemment cliqué
    map.panTo({
      lat: clickedMarker.userPositionLatitude-1/Math.pow(2,(map.getZoom()-7)),
      lng: clickedMarker.userPositionLongitude
    })
    setSelectedMarker(clickedMarker)
    // On annule l'animation et le polygon du marker précédent éventuel
    if (previousSelectedMarker) {
      RemoveAnimationAndPolygon(previousSelectedMarker)
    }
    // On anime le marker
    let userPositionIndex = userPositionsArray.findIndex(marker => (marker.pk === clickedMarker.pk))
    userPositionIDsArray[userPositionIndex].setAnimation(window.google.maps.Animation.BOUNCE)
    // On stocke le nouveau marker qui sera, la prochaione fois, le "marker précédent"
    setPreviousSelectedMarker(clickedMarker)
  }

  function LoadNewsFeed() {
    GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('MapFixedPage.js -> Chargement nouveau newsFeedContent dans le state Redux')
        dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
      } else {
        console.log('MapFixedPage.js -> Erreur dans le fetch newsFeedContent')
      }
    })
  }

  function Subscribe() {
    let fetchArgument = {
      'subscriptionUser' : myProfile.pk, //Utilisé pour que le champs soit présent et avec un bon format, mais cet id sera écrasé par le userid dans l'API
      'subscriptionSpot' : selectedMarker.pk
    }
    setIsSubscribing('subscribing')
    // Lancement animation des points gagnés
    console.log('MapFixedPage -> Déclenchement animation points gagnés subscribe')
    dispatch({ type : "TOGGLE_SCOREDPOINTS_ANIMATION", payload : 50})

    PostFunction({fetchTarget : 'postSubscription', fetchArgument : fetchArgument, token : token})
    .then(response => {
      setIsSubscribing(null)
      if(response.fetchStatus === 'Ok') {
        console.log('MapFixedPage.js -> Fin chargement API toggleSubscription')
        console.log('MapFixedPage.js -> Mise à jour icone')
        setSelectedMarkerSubscribed(true)
        console.log('MapFixedPage.js -> Fetch du nouvel état de subscriptionArray')
        GetFunction({fetchTarget : 'subscriptionsArray',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapFixedPage.js -> Chargement nouveau subscriptionsArray dans le state Redux')
            dispatch({ type : "LOAD_SUBSCRIPTIONS_ARRAY", payload:response.data})
          } else {
            console.log('MapFixedPage.js -> Impossible de charger le nouveau subscriptionsArray')
            let errorMessage = "Impossible d'afficher votre nouvel abonnement, vérifiez votre connexion"
            dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
          }
          // Récupération du nouveau newsFeed
          GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
          .then((response) => {
            if (response.fetchStatus === 'Ok') {
              console.log('MapFixedPage.js -> Chargement newsFeedContent dans le state Redux')
              dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
            } else {
              console.log('MapFixedPage.js -> Erreur dans le fetch newsFeedContent')
            }
          })
        })
        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapFixedPage.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('MapFixedPage.js -> Réception du profil à jour en échec')
          }
        })

      } else {
        console.log('MapFixedPage.js -> Chargement API toggleSubscription en erreur')
        let errorMessage = "Impossible de prendre en compte votre abonnement, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
    .then(LoadNewsFeed)
  }

  function Unsubscribe() {
    console.log('MapFixedPage.js -> unsubscribe')
    // On va chercher dans la liste des subscriptions la clé primaire de la subscription à supprimer
    let markerPk = subscriptionsArray[subscriptionsArray.findIndex(id => (selectedMarker.pk === id.subscriptionSpot))].pk 
    let fetchArgument = {
      'pk' : markerPk
      }
    setIsSubscribing('unsubscribing')
    DeleteFunction({fetchTarget : 'deleteSubscription', fetchArgument : fetchArgument, token : token})
    .then(response => {
      setIsSubscribing(null)
      if(response.fetchStatus === 'Ok') {
        console.log('MapFixedPage.js -> Fin chargement API toggleSubscription')
        console.log('MapFixedPage.js -> Mise à jour icone')
        setSelectedMarkerSubscribed(false)
        console.log('MapFixedPage.js -> Fetch du nouvel état de subscriptionArray')
        GetFunction({fetchTarget : 'subscriptionsArray', fetchArgument : null, token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapFixedPage.js -> Chargement nouveau subscriptionsArray dans le state Redux')
            dispatch({ type : "LOAD_SUBSCRIPTIONS_ARRAY", payload:response.data})
          } else {
            console.log('MapFixedPage.js -> Impossible de charger le nouveau subscriptionsArray')
            let errorMessage = "Impossible d'afficher votre désabonnement, vérifiez votre connexion"
            dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
          }
        })
        // Récupération du nouveau newsFeed
        GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapFixedPage.js -> Chargement newsFeedContent dans le state Redux')
            dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
          } else {
            console.log('MapFixedPage.js -> Erreur dans le fetch newsFeedContent')
          }
        })
        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapFixedPage.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('MapFixedPage.js -> Réception du profil à jour en échec')
          }
        })
      } else {
        console.log('MapFixedPage.js -> Chargement API toggleSubscription en erreur')
        let errorMessage = "Impossible de prendre en compte votre désabonnement, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
    .then(LoadNewsFeed)
  }

  // Fonction qu'il a fallu sortir du OnClick(), sinon il y avait une erreur
  function goToLogin() {
    navigate("/login")
  }

  function ChatWithUser() {
    let userIndexInSubscription = chatHeadersArray.findIndex(item => (item.chatroomsubscriptions[0].subscriptionUser === selectedMarker.userPositionUser))
    if (userIndexInSubscription === -1) { // On vérifie si un chat avec l'utilisateur existe déjà car sinon il faut le créer avant d'aller vers l'écran de chat
      console.log('MapFixedPage.js -> Pas de chat commencé avec cet utilisateur : on crée un nouveau header')
      let headerToCreate = {
        "chatroomsubscriptions": [
          {
              "subscriptionUser": myProfile.pk,
              "subscriptionUnreadMessages": 0
          },
          {
              "subscriptionUnreadMessages": 0,
              "subscriptionUser": selectedMarker.userPositionUser
          }
        ]
      }
      PostFunction({fetchTarget : 'createChatHeader', fetchArgument : headerToCreate, token : token})
      .then(response => {
        let newChatRoom = response.data.chatroomsubscriptions[0].subscriptionChatroom
        if (response.fetchStatus === 'Ok')  {
          console.log('MapFixedPage.js -> Chargement du chatHeadersArray à jour')
          GetFunction({fetchTarget : 'chatHeadersArray', fetchArgument : null, token : token})
          .then((response) => {
            if (response.fetchStatus === 'Ok') {
              console.log('MapFixedPage.js -> Chargement du chatHeadersArray dans le state Redux')
              dispatch({ type : "LOAD_CHAT_HEADERS_ARRAY", payload:response.data})
            } else {
              console.log('MapFixedPage.js -> Impossible de charger la liste des conversations à jour')
            }
          })
          let nextPage = "/Chat/" + newChatRoom + "/" + selectedMarker.userPositionUser
          navigate (nextPage)
        } else {
          console.log('MapFixedPage.js -> Impossible de créer une conversation avec cet utilisateur')
        }
      })
    } else {
      console.log('MapFixedPage.js -> Il y a déjà un chat commencé avec cet utilisateur : on y va')
      let existingChatroom = chatHeadersArray[userIndexInSubscription].chatroomsubscriptions[0].subscriptionChatroom
      let nextPage = "/Chat/" + existingChatroom + "/" + selectedMarker.userPositionUser
      navigate (nextPage)
    }
  }

  // Fonction qui contruit le contenu du fil
  function SpotFeedPreview() {
    if (spotFeedPreview.feedPreviewContent) { 
      if (spotFeedPreview.isFetching) { 
        return(
          <Box sx={{display : "flex", flex : 1, flexDirection : "row", justifyContent : "center", alignItems : "center"}} onClick={() => {navigate("/Feed/" + selectedMarker.pk)}}>
            <CircularProgress />
          </Box>
        )
      } else {
        if (spotFeedPreview.feedPreviewContent.results) { // Ne pas oublier le .results, car le fetch est paginé
          if (spotFeedPreview.feedPreviewContent.results.length > 0) {
            return(
              <SpotPostPreview
                onClickFunction={() => {navigate("/Feed/" + selectedMarker.pk)}}
                postlist={spotFeedPreview.feedPreviewContent.results}
              />
            )
          } else {
            return(
              <Box sx={{display : "flex", flex : 1, flexDirection : "row", justifyContent : "center", alignItems : "center"}} onClick={() => {navigate("/Feed/" + selectedMarker.pk)}}>
                <Typography align="center" variant="body1" color="primary">
                  Il n'y a pas encore de contribution pour ce lieu.
                </Typography>
              </Box>
            )
          }
        } else return null
      }
    } else return null
  }


  // Affichage du détail du marker
  function MarkerDetailCard() {
    if (selectedMarker) {
      if (selectedMarker.hasOwnProperty('spotType')) {
        return(
          <Fragment>
            <Avatar
              style={{
                border: '15px solid #f3722C' // Seule synthaxe qui fonctionne pour créer un border 
              }}
              src={"https://maps.googleapis.com/maps/api/staticmap?center=" + (selectedMarker.spotLatitude-1.2/Math.pow(2,(selectedMarker.markerImgZoom-7))).toString() + "," + selectedMarker.spotLongitude + "&zoom=" + selectedMarker.markerImgZoom + "&size=400x400&maptype=satellite&key=AIzaSyATHwwDt6IBo65JOtA0bxsJ7G8r7lk4Fdk"}  
              sx={{ position : "absolute", width: 300, height: 300, left : "50%", bottom : 70, transform: 'translateX(-50%)'}}
              onClick={() => {navigate("/Feed/" + selectedMarker.pk)}}
            />
            <Paper elevation={24}  
              sx={{
                display : "flex",
                flexDirection : "column", 
                position:"fixed", 
                justifyItems : "center",
                bottom: 0, 
                left : { xs:"0%", sm:"10%"}, 
                right : { xs:"0%", sm:"10%"}, 
                borderTopLeftRadius : 10, 
                borderTopRightRadius : 10,
                height : 300,
                marginLeft : 1,
                marginRight : 1,
                backgroundColor : "white"
              }}
            >
              {/* Croix pour fermer la fenêtre */}
              <Box sx={{position : "absolute", zIndex : 1, top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {() => {UnSelectMarker()}}>
                <IconButton color="secondary">
                  <HighlightOffIcon fontSize = "large"/>
                </IconButton>
              </Box>

              <Box sx={{display : "flex", flexDirection : "row", marginBottom : 2}}>
                <Box flex={1} onClick={() => {navigate("/Feed/" + selectedMarker.pk)}}/>
                <Box sx={{display : "flex", flex : 8, flexDirection : "column", alignItems : "center"}} onClick={() => {navigate("/Feed/" + selectedMarker.pk)}}>
                  <Typography variant="h6" component="div" color="secondary" fontWeight = "bold" sx={{textTransform: 'uppercase', textAlign : "center"}}>
                    {selectedMarker.spotName}
                  </Typography>
                  <Typography variant="body3" component="div" color="primary">
                    Abonnés : {selectedMarker.nbSubscriptions}
                  </Typography>
                </Box>
                <Box flex={1}>
                  <IconButton
                    sx={{mr : 3}}
                    onClick={(token ? (selectedMarkerSubscribed ? Unsubscribe : Subscribe) : goToLogin)}
                  >
                    {
                    (token ?
                      (isSubscribing ?
                        (isSubscribing === "subscribing" ?
                          <FavoriteIcon color="secondary" sx={{ fontSize: 40 }}/>
                          :
                          <FavoriteBorderIcon color="primary" sx={{ fontSize: 40 }}/>
                        )
                        :
                        (selectedMarkerSubscribed ?
                          <FavoriteIcon color="secondary" sx={{ fontSize: 40 }}/>
                          :
                          <FavoriteBorderIcon color="primary" sx={{ fontSize: 40 }}/>
                        )
                      )
                      :
                      <FavoriteBorderIcon color="primary" sx={{ fontSize: 40 }}/>
                    )
                    }
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", marginLeft : 1, marginBottom : 1}} >
                <Typography variant="h7" fontWeight="bold" sx={{color : "#f3722C"}} onClick={() => {navigate("/Feed/" + selectedMarker.pk)}}>
                  Dernières discussions :
                </Typography>
                <Box sx={{flex : 1}}/>
                {(selectedMarker.spotharbors ?
                  <Fragment>
                    <Typography variant="h7" fontWeight="bold" sx={{color : "#f3722C"}}>
                      Capitainerie :
                    </Typography>
                    <IconButton
                      onClick={(token ? () => {navigate("/OtherUserProfile/" + selectedMarker.spotharbors.harborUser)} : goToLogin)}
                    >
                      <FaceIcon color="secondary" sx={{ fontSize: 40 }}/>
                    </IconButton>
                  </Fragment>
                  :
                  null
                )}
              </Box>
              {SpotFeedPreview()}
            </Paper>
          </Fragment>
        )
      } else if (selectedMarker.hasOwnProperty('reportMarkType')) {
        return(
          <Box sx={{position:"fixed", bottom:{ xs:110, sm:130}, left : "50%", transform: 'translate(-50%, 0%)', width : { xs:"90%", sm:400}}}>
            {/* Croix pour fermer la fenêtre */}
            <Box sx={{position : "absolute", zIndex : 1, top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {() => {UnSelectMarker()}}>
              <IconButton color="secondary">
                <HighlightOffIcon fontSize = "large"/>
              </IconButton>
            </Box>

            <Card sx={{ width: "100%"}}>
              <CardHeader
                avatar={
                  <Avatar src={selectedMarker.icon} sx={{ width: 100, height: 100}} />
                }
                title={selectedMarker.label}
                subheader={DateFromNow(selectedMarker.reportMarkTimestamp)}
              />
              <CardActionArea onClick={() => {navigate("/Comments/" + selectedMarker.reportMarkPost.toString())}}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                  {selectedMarker.label}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <Divider variant="middle" />
              <CardActions>
                <Button size="small" onClick={() => {navigate("/Comments/" + selectedMarker.reportMarkPost.toString())}}>
                  <ThumbUpIcon  color="primary" sx={{marginRight : 1}}/>
                  <Typography variant = "body3">
                    {selectedMarker.nbLikes}
                  </Typography>
                </Button>
                <Button size="small" onClick={() => {navigate("/Comments/" + selectedMarker.reportMarkPost.toString())}}>
                  <Typography variant = "body3">
                    {selectedMarker.nbComments} commentaires
                  </Typography>
                </Button>
              </CardActions>
            </Card>
          </Box>
        )
      } else if (selectedMarker.hasOwnProperty('userPositionUser')) {      
        return(
          <Box sx={{position:"fixed", bottom:{ xs:110, sm:130}, left : "50%", transform: 'translate(-50%, 0%)', width : { xs:"90%", sm:400}}}>
            {/* Croix pour fermer la fenêtre */}
            <Box sx={{position : "absolute", zIndex : 1, top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {() => {UnSelectMarker()}}>
              <IconButton color="secondary">
                <HighlightOffIcon fontSize = "large"/>
              </IconButton>
            </Box>

            <Card sx={{ width: "100%"}}>
              <CardActionArea onClick={() => {navigate("/OtherUserProfile/" + selectedMarker.userPositionUser)}}>
                <CardContent>
                  <Box sx={{ display : "flex", flexDirection : "row", alignItems : "center", marginBottom : 1}}
                  >
                    {selectedMarker.userPositionAvatarurl ? 
                      <Avatar src={selectedMarker.userPositionAvatarurl} sx={{ width: 60, height: 60, marginRight : 1, bgcolor:"primary"}} />
                      :
                      null
                    }
                    <Box sx={{ display : "flex", flexDirection : "column", marginRight : 1}}
                    >
                      <Box sx={{ display : "flex", flexDirection : "row", alignItems : "center"}}
                      >
                        {selectedMarker.userPositionNickname}
                        {selectedMarker.userPositionGlobalBadge ?
                          <Avatar src={selectedMarker.userPositionGlobalBadge} sx={{ width: 20, height: 20, marginRight : 1, bgcolor:"primary"}} />
                          :
                          null
                        }
                      </Box>
                      <Typography
                        variant="caption"
                        color="grey"
                      >
                        {DateFromNow(selectedMarker.userPositionTimestamp)}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
              <Divider variant="middle" />
              <CardActions>
                <Button size="small" onClick={() => {navigate("/OtherUserProfile/" + selectedMarker.userPositionUser)}}>
                  <Typography variant="body2" >
                    Voir son profil
                  </Typography>
                </Button>
                <Box sx={{flex : 1}}/>
                {(token) ?
                  (selectedMarker.userPositionUser !== myProfile.pk) ?
                    <Button size="small" onClick={ChatWithUser}>
                      <Typography variant="body2" >
                        Lui écrire
                      </Typography>
                    </Button>
                    :
                    null
                  
                  :
                  null
                }
              </CardActions>
            </Card>
          </Box>
        )
      }
    } else {
      return null
    }
  }

  // Fonction déclenchée quand la carte devient Idle (donc après un mouvement)
  // Stockage dans les variables locales du centre et du zoom quand l'utilisateur navigue à la main
  // On ne stocke qu'à la fin du mouvement pour ne pas faire un refresh à chaque mouvement élémentaire
  // On lance aussi la sélection du marker après un ZoomToSearch
  // On lance la recherche des posts des spots affichés
  function IdleMap() {
    if (map) { 
      // Enregistrement du center et du zoom de la carte
      let newCenter = map.getCenter()
      localStorage.setItem('NAUTILUS_CENTER', JSON.stringify(newCenter));
      let newZoom = map.getZoom() 
      localStorage.setItem('NAUTILUS_ZOOM', JSON.stringify(newZoom));
      // Déclenche le MarkerClicked si l'évènement Idle fait suite à un ZoomToSearch terminé
      if (zoomToSearchDone) {
        setClickedMarker(zoomToSearchDone)
        zoomToSearchDone = null
      }
      // Construction de la liste des spots visibles, si on est dans l'écran MapPage
      if ((displayMap) && (markersArray)){
        let spotsIDsString = ""
        let spotsIDsArray = []
        let bounds = map.getBounds()
        for (var i=0; i<markersArray.length; i++) {
          // On récupère le LatLng de la position du market et vérifie s'il est inclus dans le bounds
          if (bounds.contains(markerIDsArray[i].getPosition()) === true) {
            // Pour chaque spot dans le bounds, on l'ajoute dans une chaine de caractère séparés par une virgule
            if (spotsIDsString === "") {
              spotsIDsString = markersArray[i].pk.toString()
            } else {
              spotsIDsString = spotsIDsString + "," + markersArray[i].pk.toString()
            }
            spotsIDsArray.push(markersArray[i].pk)
          }
        }
        
        // On réinitialise le state et on lance la récupération des premiers posts de la liste de spots
        setVisibleSpotsState(prevState => ({...prevState, 
          spotsIDsString : spotsIDsString,
          spotsIDsArray : spotsIDsArray,
        }))
        if (spotsIDsString !== "") {
          FetchNextVisibleSpotsPosts(spotsIDsString,"Page 1")
        } else {
          setVisibleSpotsState(prevState => ({...prevState,
            postsArray : [],
            previousPageLink : null,
            nextPageLink : null
          })) 
        }
      }
    }
  }

  // Fonction lancé pour aller chercher la prochaine page de posts
  function FetchNextVisibleSpotsPosts(spotsIDsString,nextPageUrl) {
    // On lance la fonction GetFunction, avec des arguments spécifiques : la liste des spots visibles, et l'url de la prochaine page à envoyer au back-end
    console.log('MapFixedPage.js -> Lancement FetchNextVisibleSpotsPosts')
    setVisibleSpotsState(prevState => ({...prevState,
      isFetchingFeed : true
    }))
    GetFunction({fetchTarget : 'visibleSpotsFeed',fetchArgument : {visibleSpotsIDs : spotsIDsString, nextPageUrl : ((nextPageUrl === "Page 1") ? "" : nextPageUrl)}})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('MapFixedPage.js -> Chargement postsArray et nextPageLink dans le state')
        setVisibleSpotsState(prevState => ({...prevState,
          postsArray : response.data.results, // Comme c'est paginé, il faut récupérer l'objet result
          previousPageLink : response.data.previous,
          nextPageLink : response.data.next
        })) 
        } else {
        console.log('MapFixedPage.js -> Erreur dans FetchNextVisibleSpotsPosts')
      }
      setVisibleSpotsState(prevState => ({...prevState,
        isFetchingFeed : false
      }))
  
    })
  }

  //
  // AFFICHAGE DU VISIBLE SPOTS FEED
  //

  // On crée la VisibleSpotsFeedBox
  function VisibleSpotsFeedBox() {
    return(
      <Fragment>
        <Paper elevation={24}  
          sx={{
            display : (visibleSpotsState.displayFeed ? "flex" : "none"), 
            flexDirection : "column", 
            position:"fixed", 
            justifyItems : "center",
            bottom: 0, 
            left : { xs:"0%", sm:"10%"}, 
            right : { xs:"0%", sm:"10%"}, 
            borderTopLeftRadius : 10, 
            borderTopRightRadius : 10,
            height : 300,
            marginLeft : 1,
            marginRight : 1,
            backgroundColor : "white"
          }}
        >
          {/* Croix pour fermer la fenêtre */}
          <Box sx={{position : "absolute", zIndex : 1, top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {() => { setVisibleSpotsState(prevState => ({...prevState, displayFeed : false }))}}>
            <IconButton color="secondary">
              <HighlightOffIcon fontSize = "large"/>
            </IconButton>
          </Box>

          <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", marginBottom : 2}}>
            <Box sx={{display : "flex", flex : 1, ml : 2}}>
              <IconButton
                disabled={(visibleSpotsState.previousPageLink === null)}
                onClick={() => {FetchNextVisibleSpotsPosts(visibleSpotsState.spotsIDsString,visibleSpotsState.previousPageLink)}}
                size="large"
                color={'secondary'}
              >
                <ArrowBackIos/>
              </IconButton>
            </Box>
            <Box sx={{display : "flex", flex : 10, justifyContent:"center"}}>
              <ForumIcon color= 'secondary' sx={{ mr : 1 }}/>
              <Typography variant="overline"  fontWeight = "bold" component="div" color="#f3722C">
                {"Discussions dans cette zone"}
              </Typography>
            </Box>
            <Box sx={{display : "flex", flex : 1, mr : 2}}>
              <IconButton
                disabled={(visibleSpotsState.nextPageLink === null)}
                onClick={() => {FetchNextVisibleSpotsPosts(visibleSpotsState.spotsIDsString,visibleSpotsState.nextPageLink)}}
                size="large"
                color={'secondary'}
              >
                <ArrowForwardIos/>
              </IconButton>
            </Box>
          </Box>
          <Box sx={{display : "flex", flexDirection : "column"}} overflow="auto">
            {(visibleSpotsState.displayFeed ? 
              (visibleSpotsState.isFetchingFeed ?
                <Box sx={{display : "flex", flex : 1, flexDirection : "row", justifyContent : "center", alignItems : "center"}}>
                  <CircularProgress />
                </Box>
                :
                visibleSpotsState.feed
              )
              : 
              null
            )}
          </Box>        
        </Paper> 
      </Fragment>
    )
  }

  // On construit le feed dans un state pour éviter qu'il soit généré par une function qui fase de nombreux render avec l'animation
  useEffect(()=> {
    console.log('MapFixedPage -> Construction du visibleSpotsState')
    if (visibleSpotsState.postsArray.length > 0) {
      console.log('MapFixedPage -> visibleSpotsState existant à construire')
      setVisibleSpotsState(prevState => ({
        ...prevState, feed : 
        <Fragment>
          <PostPreview
            postlist={visibleSpotsState.postsArray}
          />
        </Fragment>
      }))
    } else {
      console.log('MapFixedPage -> visibleSpotsState vide')
      setVisibleSpotsState(prevState => ({
        ...prevState, feed : 
          <Typography align="center" variant="body1" color="primary">
            Il n'y a aucune publication dans cette zone.
          </Typography>
      }))
    }
},[visibleSpotsState.postsArray])

  // Fonction appelée quand un choix est fait dans la barre de recherche  
  function ZoomToSearch() {
    map.setZoom(12)
    if (!zoomToSearch.hasOwnProperty('reportMarkType')) {
      map.panTo({lat:zoomToSearch.spotLatitude-1/Math.pow(2,(12-7)),lng:zoomToSearch.spotLongitude})
    } else {
      map.panTo({lat:zoomToSearch.reportMarkLatitude-1/Math.pow(2,(12-7)),lng:zoomToSearch.reportMarkLongitude})
    }
    // Si on appelait markerClicked tout de suite, il bloquerait le setCenter : pour attendre
    // que le setCenter soit fait, on passe le marker dans une variable qui sera lue dans
    // la fonction OnIdle()
    zoomToSearchDone = zoomToSearch
  }

  function HandleMapClick(event) {
  // Fermeture de l'affichagr d'un mmarker
    if (selectedMarker) {
      UnSelectMarker()
    }
    // Fermeture de l'affichage du visible SpotsFeed
    setVisibleSpotsState(prevState => ({...prevState, displayFeed : false }))

    // Si on est en train de créer un spot, on positionne le nouveau marker.
    if (newContentState.isSettingNewSpot === true) {
      if (newSpotMarkID) {
        newSpotMarkID.setMap(null)
        setNewSpotMarkID(null)
      } 
      let markerPosition = {lat:event.latLng.lat(), lng:event.latLng.lng()}
      let markerOption = {
        map: map,
        position: markerPosition,
        icon : { url : "/static/Mark.svg", scaledSize : {width:pinWidth, height:pinHeight}},
        animation : window.google.maps.Animation.DROP
      };
      map.panTo({
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      })
      setNewSpotMarkID(new window.google.maps.Marker(markerOption))
      setNewContentState(prevState => ({...prevState, newSpotMarker : markerPosition,isSettingNewSpot:"CreatePolygon"}))
    }

    // Si on est en train de faire un polygone : on ajoute un marker et on  ajoute le listener click du premier
    if (newContentState.isSettingNewSpot === "CreatePolygon") {
      if (!newPolygonIsClosed) {
        // On ajoute un nouveau marker
        var newMarker = new window.google.maps.Marker({ map: map, position: event.latLng});
        newPolygonMarkersArray.push(newMarker)
        // On ajoute au premier marker un listener pour qu'il termine le tracé quand il est cliqué
        if (currentPolyline.getPath().length === 0) {
          newMarker.addListener("click", () => {
            if (!newPolygonIsClosed) {
              // Si on a cliqué sur le marqueur du début : on enregistre le tracé du polyline dans un polygone, on efface la polyline et on marque le tracé comme terminré
              setNewContentState(prevState => ({...prevState,
                newSpotPolygonPath : currentPolyline.getPath(),
                newSpotPolygonJSON : JSON.stringify(currentPolygonArray),
                isSettingNewSpot : false
              }))
              // On efface la ligne, on la marque comme fermée, et on la réinitialise
              currentPolygon = new window.google.maps.Polygon({ map: map, path: currentPolyline.getPath(), strokeColor: "#FF0000", strokeOpacity: 0.8, strokeWeight: 2, fillColor: "#FF0000", fillOpacity: 0.35 });
              currentPolyline.setMap(null);
              newPolygonIsClosed = true;
              newPolygonMarkersArray.forEach(marker => {
                marker.setMap(null)
              })
              newPolygonMarkersArray = []
              newSpotMarkID.setMap(null)
              newPolygonIsClosed = false;
              currentPolygon.setMap(null)
              navigate("/MapNewMarker" , {state : {reportMarker : newContentState.newSpotMarker, spotPolygon : JSON.stringify(currentPolygonArray)}})
                      }
          })
        }
        // On ajoute le point à la polyline et, en parallèle, à l'objet qui sera transformé en JSON puis stocké dans la base de données
        currentPolyline.getPath().push(event.latLng);
        currentPolygonArray.push({lat:event.latLng.lat(), lng:event.latLng.lng()})
      }
    }
  }


  function HandleMapDrag(event) {
    // Fermeture de l'affichage d'un mmarker
    if (selectedMarker) {
      UnSelectMarker()
    }
  }
  
  
  //
  // MENUS DES FLOATING BUTTONS
  //

  function LeftFABs() {
    if (displayFABs) {
      return(
        <Box sx={{position : "absolute", display : "flex", flexDirection : "column", justifyContent: "flex-end", bottom : 10, left : 10}}>
          <NewMultiPostFAB/>
        </Box>
      )
    }
  }

  function VisibleSpotsFeedFABs() {
    if (displayFABs) {
      return(
        <Box sx={{position : "absolute", display : "flex", flexDirection : "column", justifyContent: "center", bottom : 250, left : "50%", transform : "translateX(-50%)"}}>
          <HideVisibleSpotsFeedFAB/>
        </Box>
      )
    }
  }

  function CenterBottomFABs() {
    if (displayFABs) {
      return(
        <Box sx={{position : "absolute", display : "flex", flexDirection : "column", justifyContent: "center", bottom : -40, left : "50%", transform : "translateX(-50%)"}}>
          <DisplayVisibleSpotsFeedFAB/>
        </Box>
      )
    }
  }

  function RightFABs() {
    if (displayFABs) {
      return(
        <Box sx={{position : "absolute", display : "flex", flexDirection : "column", justifyContent: "flex-end", bottom : 10, right : 10}}>
          <ZoomPositionFAB/>
          <NewReportMarkFAB/>
          <NewSpotFAB/>
          <MultiPostSpotsSelectionButtons/>
          <NewReportMarkFloatingActionButtons/>
          <NewSpotFloatingActionButtons/>
        </Box>
      )
    }
  }

  function NewMultiPostFAB() {
    return(
      <Fab color="secondary" onClick={StartSpotsSelection}
        sx={{
          display : (((newContentState.isSelectingSpotsForMultipost === false) && (newContentState.isSettingNewReportMark === false) && (newContentState.isSettingNewSpot === false) && (selectedMarker === null) && (visibleSpotsState.displayFeed === false)) ? "flex" : "none"), 
          marginTop : 0.5, marginBottom : 0.5 
        }}>
        <SendIcon sx={{color : "white"}}/>
      </Fab>
    )
  }
  
  function ZoomPositionFAB() {
    return(
      <Fab color="primary" onClick={ZoomPosition} 
        sx={{
          display : (((newContentState.isSelectingSpotsForMultipost === false) && (newContentState.isSettingNewReportMark === false) && (newContentState.isSettingNewSpot === false) && (selectedMarker === null) && (visibleSpotsState.displayFeed === false)) ? "flex" : "none"), 
          marginTop : 0.5, marginBottom : 0.5 
        }}
      >
        <GpsFixedIcon sx={{color : "white"}}/>
      </Fab>
    )
  }
  
  function NewReportMarkFAB() {
    return(
      <Fab color="secondary" onClick={StartNewReportMarkPositioning} 
        sx={{
          display : (((newContentState.isSelectingSpotsForMultipost === false) && (newContentState.isSettingNewReportMark === false) && (newContentState.isSettingNewSpot === false) && (selectedMarker === null) && (visibleSpotsState.displayFeed === false)) ? "flex" : "none"), 
          marginTop : 0.5, marginBottom : 0.5 
        }}
      >
        <AddLocationIcon sx={{color : "white"}}/>
      </Fab>
    )
  }
  
  function DisplayVisibleSpotsFeedFAB() {
    return(
    <Fab color="secondary"
      sx={{
        display : (((newContentState.isSelectingSpotsForMultipost === false) && (newContentState.isSettingNewReportMark === false) && (newContentState.isSettingNewSpot === false) && (selectedMarker === null) && (visibleSpotsState.displayFeed === false)) ? "flex" : "none"), 
        height : 80, width : 80
      }}
      onClick={() => {
        setVisibleSpotsState(prevState => ({...prevState, displayFeed : true }))
      }} 
    >
      <ForumIcon sx={{color : "white", mb : 5}}/>
    </Fab>
  )
}

  function HideVisibleSpotsFeedFAB() {
    return(
    <Fab color="secondary"
      sx={{
        display : (((visibleSpotsState.displayFeed === true)) ? "flex" : "none"), 
        height : 80, width : 80
      }}
      onClick={() => {
        setVisibleSpotsState(prevState => ({...prevState, displayFeed : false }))
      }} 
    >
      <ExpandMoreIcon sx={{color : "white", mb : 5}} size="large"/>
    </Fab>
  )
}

  function ZoomPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log('MapFixedPage.js -> Nouvelle position acquise')
        if (map) {
          map.setZoom(12)
          map.panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
          if (userPositionID) {
            // On supprime le markers éventuellement existant de la carte
            userPositionID.setMap(null)
          }
          let markerOption = {
            map: map,
            position: {lat:position.coords.latitude, lng:position.coords.longitude},
            icon : {url : "/static/My-Location-Pin-Me.svg", scaledSize : {width:userPinWidth, userPinHeight}, anchor : new window.google.maps.Point(userPinWidth/2,userPinHeight/2)},
            animation : window.google.maps.Animation.DROP
          };
          let newUserPositionID = new window.google.maps.Marker(markerOption);
          // Stockage des références des markers dans un state
          setUserPositionID(newUserPositionID)
        }
      }, () => {
        // Géolocalisation impossible ou désactivée
        // Affichage d'un message d'erreur
        let errorMessage = "Impossible d'afficher votre position. Vérifiez que vous avez autorisé votre navigateur à vous localiser. Si le message d'affiche de manière répétée, désactivez le suivi de position dans votre profil ou sur la carte."
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      })
    } else {
      // Browser incompatible
      // Affichage d'un message d'erreur
      let errorMessage = "La version de votre navigateur ne permet pas la géolocalisation. "
      dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
    }
  }

  function MultiPostSpotsSelectionButtons() {
    if (newContentState.isSelectingSpotsForMultipost === true) {
      return(
        <Fragment>
          <Fab
            color="success"
            variant="extended"
            size="small"
            disabled={(newContentState.selectedSpots.length ===0)}
            onClick={GoToNewPostScreen}
            sx={{
              marginTop : 0.5, marginBottom : 0.5, 
              animation: `${buttonEffect} 0.5s ease infinite`
            }}
          >
            <SendIcon/>
            Ecrire la publication
          </Fab>
          <Fab color="error" variant="extended" size="small" onClick={EndSpotSelection} 
            sx={{
              marginTop : 0.5, marginBottom : 0.5, 
            }}
          >
            <CancelIcon/>
            Annuler
          </Fab>
        </Fragment>
      )
    }
  }

  function NewReportMarkFloatingActionButtons() {
    if (newContentState.isSettingNewReportMark !== false) {
     return(
      <Fragment>
        <Fab 
          color="success" variant="extended" size="small" onClick={NewReportMarkSet}
          sx={{
            display : (newContentState.isSettingNewReportMark === true ? "flex" : "none"),
            marginTop : 0.5, marginBottom : 0.5, 
            animation: `${buttonEffect} 0.5s ease infinite`
          }}
        >
          <AddLocationIcon/>
          Créer ici
        </Fab>
        <Fab color="error" variant="extended" size="small" onClick={EndNewReportMarkSelection}
        sx={{
          marginTop : 0.5, marginBottom : 0.5, 
        }}
        >
          <CancelIcon/>
          Annuler
        </Fab>
      </Fragment>
     )
    } 
  }

  function NewSpotFAB() {
    if (myProfile.userIsStaff === true) {
      return(
        <Fab color="error" size="large" onClick={StartNewSpot} 
          sx={{
            display : (((newContentState.isSelectingSpotsForMultipost === false) && (newContentState.isSettingNewReportMark === false) && (newContentState.isSettingNewSpot === false) && (selectedMarker === null) && (visibleSpotsState.displayFeed === false)) ? "flex" : "none"),
            marginTop : 0.5, marginBottom : 0.5 
          }}
        >
          <AddLocationIcon sx={{color : "white"}}/>
        </Fab>
      )
    }
  }

  function NewSpotFloatingActionButtons() {
    if (newContentState.isSettingNewSpot !== false) {
      return(
        <Fab color="error" variant="extended" size="small" onClick={EndNewSpotCreation} sx={{mb : 1}}>
          <CancelIcon sx={{mr : 1}}/>
          Annuler
        </Fab>
      )
    } 
  }

  //
  // ZONE DES INFO CHIPS
  //

  function MultiPostSpotsSelectionChip() {
    if (newContentState.isSelectingSpotsForMultipost === true) {
      if (newContentState.selectedSpots.length >= multipostMaxSpots) {
        return(
          <Chip
            label={"Maximum de " + multipostMaxSpots + " spots sélectionnés"}
            color="warning"
            sx={{
              animation: `${buttonEffect} 0.5s ease infinite`,
              padding : 1,
              marginBottom : 1,
            }}
          />
        )
      } else {
        return(
          <Chip
            label="Sélectionnez les spots où vous voulez publier"
            color="primary"
            sx={{
              animation: `${buttonEffect} 0.5s ease infinite`,
              marginBottom : 1,
              padding : 1
            }}
          />
        )
      }
    }
  }

  function NewReportMarkChip() {
    if (newContentState.isSettingNewReportMark === true) {
      return(
        <Chip
          label="Placez le signalement"
          color="primary"
          sx={{
            animation: `${buttonEffect} 0.5s ease infinite`,
            marginBottom : 1,
            padding : 1
          }}
        />
      )
    } 
  }

  function NoPositionChip() {
    if (token) {
      if (myProfile.userPositionShare === false) {
        return(
          <Chip
            label={
              <Box
                sx={{
                  textAlign: 'center',     // Centrer le texte horizontalement
                  whiteSpace: 'normal',    // Pour permettre d'afficher le texte sur plusieurs lignes
                  display: 'block',        // Assure que le texte prend tout l'espace
                }}
              >
                Vous ne partagez pas votre position.
                <br/>
                Activez le partage dans votre profil pour voir les autres marins
              </Box>
            } 
            color="error"
            sx={{
              marginBottom : 1,
              // Pour que la chip puisse être sur plusieurs lignes
              height: 'auto',
              padding : 1,
              '& .MuiChip-label': {
                display: 'block',
                whiteSpace: 'normal',
              }
            }}
          />
        )
      }
    } 
  }

  function NewReportMarkTarget() {
    if (newContentState.isSettingNewReportMark === true) {
      return(
        <Box
        component="img"
        sx={{
          position : "fixed",
          right : "50%",
          top : "50%",
          transform: "translate(50%, -100%)",
          height: 60,
          width: 60,
        }}
        src={"/static/Mark.svg"}
      />
      )
    } 
  }


  // Menu des info chips
  function InfoChipsZone() {
    return(
      <Box sx={{display : "flex", alignItems : "center", justifyContent : "center", flexDirection : "column", position : "fixed", top : { xs:65, sm:75}, right : 10, left : 10}}>
        {MultiPostSpotsSelectionChip()}
        {NewReportMarkChip()}
        {NoPositionChip()}
      </Box>
    )
  }

  //
  // ENVOI D'UN POST A PLUSIEURS SPOTS
  //
  
  // Fonction lancée au click du bouton : lancement de l'écran de sélection des spots
  function StartSpotsSelection() {
    // Fermeture du dernier marker
    if (selectedMarker) {
      UnSelectMarker()
    }
    map.setZoom(map.getZoom()-1)
    let newSelectedSpots = []
    // Fermeture du visibleSpotsFeeed
    setVisibleSpotsState(prevState => ({...prevState, displayFeed : false }))
    // Sélection des spots visibles
    for (var i=0; i<Math.min(visibleSpotsState.spotsIDsArray.length,multipostMaxSpots); i++) {
      let markerIndex = markersArray.findIndex(item => (item.pk === visibleSpotsState.spotsIDsArray[i]))
      let marker = markersArray[markerIndex]
      let markerID = markerIDsArray[markerIndex]
      newSelectedSpots.push(marker)
      markerID.setAnimation(window.google.maps.Animation.BOUNCE)
      if (marker.spotType === "Mouillage") {
        markerIcon = {url : "/static/Mouillage-Multipost.svg", scaledSize : {width:pinWidth, height:pinHeight}}
      } else {
        markerIcon = {url : "/static/Port-Multipost.svg", scaledSize : {width:pinWidth, height:pinHeight}}
      }  
      markerID.setIcon(markerIcon)
      polygonIDsArray[markerIndex].setOptions(polygonOptionsMultipost)
    }

    // Passage à l'étape de sélection des spots
    setNewContentState(prevState => ({...prevState,isSelectingSpotsForMultipost:true, selectedSpots:newSelectedSpots}))
  }

  /// Fonction qui réafecte un icône à chaque marker quand on quitte la sélection
  function ResetMarkerIcon(markerArgument) {
    if (token) {
      if (subscriptionsArray.findIndex(id => (markerArgument.pk === id.subscriptionSpot)) !== -1) {
        if (markerArgument.spotType === "Mouillage") {
          markerIcon = {url : "/static/Mouillage-Subscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        } else {
          markerIcon = {url : "/static/Port-Subscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        }  
      } else {
        if (markerArgument.spotType === "Mouillage") {
          markerIcon = {url : "/static/Mouillage-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        } else {
          markerIcon = {url : "/static/Port-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
        }  
      }
    } else {
      if (markerArgument.spotType === "Mouillage") {
        markerIcon = {url : "/static/Mouillage-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
      } else {
        markerIcon = {url : "/static/Port-Unsubscribed.svg", scaledSize : {width:pinWidth, height:pinHeight}}
      }  
    }

    let markerIndex = markersArray.findIndex(marker => (marker.pk === markerArgument.pk))
    markerIDsArray[markerIndex].setIcon(markerIcon)
    markerIDsArray[markerIndex].setAnimation(null)
    polygonIDsArray[markerIndex].setOptions(polygonOptionsNormal)
  }


  function GoToNewPostScreen() {
    // Passage à la rédaction du post, si on a sélectionné au moins un spot
    setNewContentState(prevState => ({...prevState,isSelectingSpotsForMultipost:false}))
    newContentState.selectedSpots.forEach(item => {
      ResetMarkerIcon(item)
    })
    navigate("/MapNewPost" , {state : {spots : newContentState.selectedSpots}})
  }

  function EndSpotSelection() {
    // Remise à zéro et sortie du process
    newContentState.selectedSpots.forEach(item => {
      ResetMarkerIcon(item)
    })
    setNewContentState(prevState => ({...prevState,isSelectingSpotsForMultipost:false, selectedSpots:[]}))
  }

  //
  // CREATION D'UN SIGNALEMENT
  //
    
  function StartNewReportMarkPositioning() {
    if (selectedMarker) {
      UnSelectMarker()
    }
    // Fermeture du visibleSpotsFeeed
    setVisibleSpotsState(prevState => ({...prevState, displayFeed : false }))
    // Passage à l'étape de positionnement du marker
    setNewContentState(prevState => ({...prevState,isSettingNewReportMark: true}))
  }

  function NewReportMarkSet() {
    if (newReportMarkID) {
      newReportMarkID.setMap(null)
      setNewReportMarkID(null)
    } 
    
    // Acquisition des coordonnées du centre de la carte
    console.log('MapFixedPage -> Acquisition des coordonnées du centre de la carte')
    let mapCenter = map.getCenter()
    let mapCenterPosition = {lat:mapCenter.lat(), lng:mapCenter.lng()}

    let markerOption = {
      map: map,
      position: mapCenterPosition,
      icon : { url : "/static/Mark.svg", scaledSize : {width:60, height:60}},
      animation : window.google.maps.Animation.DROP
    };
    
    map.panTo({
      lat: mapCenter.lat()-1/Math.pow(2,(map.getZoom()-7)),
      lng: mapCenter.lng()
    })
    setNewReportMarkID(new window.google.maps.Marker(markerOption))
    setNewContentState(prevState => ({...prevState, newReportMark : mapCenterPosition,isSettingNewReportMark:"ReportTypeSelection"}))
  }

  function GoToNewReportScreen(item) {
    // Passage à l'étape de la rédaction du signalement
    setNewContentState(prevState => ({...prevState,isSettingNewReportMark:false}))
    newReportMarkID.setMap(null)
    navigate("/MapNewMark" , {state : {reportType : item, reportMark : newContentState.newReportMark}})
  }

  function EndNewReportMarkSelection() {
    // Sortie de la fonction de signalement
    if (newReportMarkID) {
      newReportMarkID.setMap(null)
    }
    setNewContentState(prevState => ({...prevState,isSettingNewReportMark:false, newReportMark : null}))
  }

  function ReportTypeSelectionScreen() {
    if (newContentState.isSettingNewReportMark === "ReportTypeSelection") {
      return(
        <Paper sx={{position : "fixed", display : "flex", flexDirection : "row", alignItems : "start", flexWrap: "wrap", backgroundColor : "white", bottom:{ xs:150, sm:160}, left : "50%", transform: 'translate(-50%, 0%)', width : { xs:"90%", sm:400}, borderRadius : 10}} elevation={24} >
          {reportTypesArray.map((item, index) => {
            return(
              <Box key={index} sx={{display : "flex", flexDirection : "column", justifyContent : "center",  padding : 2}}>
                <Box
                  component="img"
                  sx={{
                   height : 50,
                   width : 50
                  }}
                  src={reportTypesArray[index].icon}
                  onClick={() => {GoToNewReportScreen(item)}}
                />
                <Typography variant="body2"  component="div">
                  {item.label}
                </Typography>
              </Box>
            )
          })}
        </Paper>
      )
    } else return null
  }

  //
  // CREATION NOUVEAU SPOT
  //

  function StartNewSpot() {
    // Désactivation des polygones
    // ATTENTION : elle est définitive, dont les polygones restent désactivés si on annule la création,
    // ils ne se réactivent que si on crée réellement le spot, ce qui déclenche la réinitialisation des polygons
    polygonIDsArray.forEach(polygonID => polygonID.setOptions(polygonOptionsDesactivated))

    currentPolyline = new window.google.maps.Polyline({ map: map, path: [], strokeColor: "#FF0000", strokeOpacity: 1.0, strokeWeight: 2 });
    currentPolygonArray = []
    if (selectedMarker) {
      UnSelectMarker()
    }
   
    // Passage à l'étape de positionnement du marker
    setNewContentState(prevState => ({...prevState,isSettingNewSpot: true}))
  }

  function EndNewSpotCreation() {
    // Sortie de la fonction de signalement
    if (newSpotMarkID) {
      newSpotMarkID.setMap(null)
    }
    newPolygonIsClosed = false;
    if (currentPolygon) {
      currentPolygon.setMap(null)
    }
    if (newPolygonMarkersArray) {
      newPolygonMarkersArray.forEach(marker => {
        marker.setMap(null)
      })
    }
    if (currentPolyline) {
      currentPolyline.setMap(null)
    }
    setNewContentState(prevState => ({...prevState,isSettingNewSpot:false, newSpotMarker : null}))
  }


  //
  //  AFFICHAGE
  //

  // Fonction qui montre la carte, ou la cache pour laisser apparaitre les autres écrans quand on sort du menu MapPage
  // Permet de ne pas supprimer/recréer la carte à chaque navigation dans les page (coûte des crédit d'API GoogleMaps)

  function MapSize() {
    if (displayMap) {
      return "100%"
    } else {
      return "0%"
    }
  }

  // Ecrans affichés par dessus la carte

  function AdditionalScreens() {
    if (displayMap) {
      return(
        <Fragment>
          <RightFABs/>
          <VisibleSpotsFeedFABs/>
          <CenterBottomFABs/>
          <LeftFABs/>
          <VisibleSpotsFeedBox/>
          <MarkerDetailCard/>
          <InfoChipsZone/>
          <NewReportMarkTarget/>
          <ReportTypeSelectionScreen/>
          <TutorialScreens/>
        </Fragment>      
      )
    } 
  }

  return (
    <Box sx={{width : "100%", height : MapSize()}}>
      <div style={{ width: "100%", height: "100%" }} id="map"/> 
      <AdditionalScreens/>
    </Box>
  )
};
